import { useState } from "react";
import { supabaseClient } from "../utility";

export const useTeamInvitation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const declineInvitation = async (id: string) => {
    await supabaseClient.functions.invoke("invite-new-member", {
      method: "DELETE",
      body: {
        id,
      },
    });
  };
  const acceptInvitation = async (id: string) => {
    setIsLoading(true);
    try {
      await supabaseClient.functions.invoke("invite-new-member", {
        method: "PUT",
        body: {
          id,
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  return { declineInvitation, acceptInvitation, isLoading };
};
