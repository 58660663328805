import { useGetIdentity } from "@refinedev/core";
import React from "react";
import { supabaseClient } from "../utility";

export const useUser = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [user, setUser] = React.useState<any>();
  const { data: me } = useGetIdentity<any>();

  const deleteUser = async (id: string) => {
    setIsLoading(true);
    try {
      await supabaseClient.auth.admin.deleteUser(id, false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const addUser = async ({ email, password, ...others }: any) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabaseClient.auth.signUp({
        email,
        password,
        options: {
          data: {
            ...others,
          },
        },
      });
      if (error) throw error;
      return data;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const updateName = async ({ name, uuid }: { name: string; uuid: string }) => {
    setIsLoading(true);

    try {
      const res = supabaseClient.rpc("update_account", {
        name: name,
        account_id: uuid,
      });
      setIsLoading(false);
      return res;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const updateAuth = async (props: {
    email?: string;
    password?: string;
    email_change_token_new?: string;
    email_change?: string;
  }) => {
    setIsLoading(true);
    try {
      const response = await supabaseClient.auth.updateUser(props);

      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const fetchUser = React.useCallback(async () => {
    try {
      if (me?.id) {
        const { data } = await supabaseClient.rpc("get_account", {
          account_id: me?.id,
        });
        setUser(data);
      }
    } catch (error) {
      console.log("Error getting account", error);
    }
  }, [me?.id]);
  React.useEffect(() => {
    fetchUser();
    return () => {
      setUser(undefined);
    };
  }, [fetchUser, me?.id]);
  return {
    isLoading,
    user,
    setIsLoading,
    addUser,
    deleteUser,
    updateName,
    updateAuth,
    fetchUser,
  };
};
