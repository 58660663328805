import { useSelect } from "@refinedev/antd";
import { useUser } from "../contexts/ContextProvider";
import { ContactType } from "../interfaces/contact.interface";

export const useContactSelect = () => {
  const { currentTeam } = useUser();

  return useSelect<{
    id: number | string;
    name_prefix?: string;
    firstname?: string;
    lastname?: string;
    type: ContactType;
    company_name?: string;
    email?: string;
  }>({
    resource: "contacts",
    // optionLabel: "firstname"| ,
    optionLabel: "firstname",
    optionValue: "id",
    filters: [
      {
        field: "account",
        value:
          currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
        operator: "eq",
      },
    ],
    meta: {
      select: "id, firstname, lastname, type, company_name, name_prefix, email",
    },
  });
};
