import { useSelect } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Form, FormItemProps, Select, SelectProps } from "antd";
import { useUser } from "../../contexts/ContextProvider";

type Props = {
  contactId: string;
  name?: (string | number)[] | string;
  label?: string;
  onSelect?: (address: any) => void;
  resetField?: (address: any) => void;
  multiple?: boolean;
  itemProps?: FormItemProps;
  selectProps?: SelectProps;
  value?: any;
};

export default function AddressAutoselect({
  contactId,
  name,
  label,
  onSelect,
  multiple,
  itemProps,
  selectProps: customSelectProps,
  value,
}: Props) {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { selectProps: refinedSelectProps, query } = useSelect<any>({
    resource: "addresses",
    meta: { select: "*, contacts!inner(*)" },
    filters: [
      { field: "contacts.id", operator: "eq", value: contactId },
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
    optionValue: (item) => item.id,
    optionLabel(item) {
      let label = "";

      if (item.type === "PO_Box_Address") {
        label += `${item.street}\n`;
      } else if (item.type === "Packing_Station") {
        if (item.address_supplement_1) {
          label += `${translate("contacts.post_number")}: ${
            item.address_supplement_1
          }\n`;
        }
        label += `${translate("contacts.packstation")}: ${item.street}\n`;
      } else {
        if (item.address_supplement_1) {
          label += `${item.address_supplement_1}\n`;
        }
        label += `${item.street}\n`;
      }

      label += `${item.zip} ${item.city}\n`;
      label += `${item.country}\n`;
      if (item.label) {
        label += `[${item.label}]\n`;
      }

      return label.trim();
    },
  });
  return (
    <Form.Item
      name={name ?? undefined}
      label={translate(label ?? "contacts.address")}
      {...itemProps}
      rules={[{ required: true }]}
    >
      <Select
        {...refinedSelectProps}
        mode={multiple ? "multiple" : undefined}
        onChange={(value) => {
          onSelect?.(value);
        }}
        value={typeof value != "object" ? value : undefined}
        allowClear
        {...customSelectProps}
      />
    </Form.Item>
  );
}
