import { useGetIdentity, useList } from "@refinedev/core";
import { Spin } from "antd";
import React from "react";
import { Navigate } from "react-router-dom";

export default function RedirectTeam() {
  const { data: me } = useGetIdentity<any>();
  const { data: invitations } = useList({
    resource: "user_invitations",
    meta: { schema: "basejump" },
    filters: [{ field: "email", value: me?.email, operator: "eq" }],
  });

  if (!invitations) {
    return <Spin />;
  }
  if (invitations?.data.length) {
    return <Navigate to={"/workspaces"} />;
  }
  return <Navigate to={"/team/create"} />;
}
