import { Spin } from "antd";
import React from "react";
import { Navigate } from "react-router-dom";
import useTeam from "../hooks/useTeam";

export const RedirectHomeRoute: React.FC = () => {
  const { allTeams, personalAccount } = useTeam();
  if (allTeams === undefined || personalAccount === undefined) {
    return <Spin />;
  } else {
    if (allTeams.length > 1) {
      const currentTeam = allTeams.filter((t) => !t.personal_account)[0];

      return (
        <Navigate
          to={`/${currentTeam.slug}/${
            !currentTeam.metadata?.module?.welcome == false ? "welcome" : ""
          }`}
        />
      );
    } else if (personalAccount?.metadata.initialSetup != true) {
      return <Navigate to="/account/setup" />;
    } else {
      return <Navigate to="/team" />;
    }
  }
};
