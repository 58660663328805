import {
  ApartmentOutlined,
  PicLeftOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Button, Card, Flex, Space, Spin, Typography } from "antd";
import React, { ReactNode, useState } from "react";
import CreateContactModal from "../../components/contacts/CreateContactModal";
import PipelineCreateModal from "../../components/pipelines/PipelineCreateModal";
import { useCan, useNavigation, useTranslate } from "@refinedev/core";
import { usePipelineContext } from "../../contexts/PipelineProvider";
import { useContactSelect } from "../../hooks/useContactSelect";
import { useUser } from "../../contexts/ContextProvider";
import TeamSettings from "../../components/teams/settings/team-settings";

type CardItemProps = {
  title?: string;
  description?: string;
  buttonText?: string;
  icon?: ReactNode;
  onClick?: (e: any) => void;
  disabled?: boolean;
};
const CardItem = ({
  title,
  description,
  buttonText,
  icon,
  disabled,
  onClick,
}: CardItemProps) => (
  <Card styles={{ body: { padding: 15 } }}>
    <Flex justify="space-between" align="center">
      <Space direction="vertical">
        {title && (
          <Typography.Title disabled={disabled} level={4} style={{ margin: 0 }}>
            {title}
          </Typography.Title>
        )}
        {description && (
          <Typography.Text disabled={disabled}>{description}</Typography.Text>
        )}
      </Space>
      <Button
        type="text"
        style={{ color: "green" }}
        onClick={(e) => onClick?.(e)}
        disabled={disabled}
      >
        {icon ?? ""}
        {buttonText ?? ""}
      </Button>
    </Flex>
  </Card>
);
export default function Onboarding() {
  const [openModal, setOpenModal] = useState<
    "contacts" | "pipelines" | "settings"
  >();
  const { data } = useCan({
    resource: "welcome",
    action: "list",
  });
  const { list } = useNavigation();

  const translate = useTranslate();
  const { updateTeam, currentTeam, refetchTeams } = useUser();

  const { pipelines, canAccess, setCanAccess } = usePipelineContext();
  const { query } = useContactSelect();
  if (data?.can == undefined) {
    return <Spin />;
  }
  if (data?.can == false) {
    list("dashboard");
  }
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        padding: "10px 20%",
      }}
    >
      <Space direction="vertical">
        <Typography.Title level={3} style={{ marginBottom: 0 }}>
          {translate("welcome.salutation")}
        </Typography.Title>
        <Typography.Title level={3}>
          {translate("welcome.title")}
        </Typography.Title>
        <Typography>{translate("welcome.message")}</Typography>
      </Space>
      <CardItem title="Onboarding" />
      <CardItem
        title={translate("welcome.set_avatar_title")}
        description={translate("welcome.set_avatar_description")}
        buttonText={translate("welcome.set_avatar_button")}
        icon={<PicLeftOutlined />}
        onClick={() => setOpenModal("settings")}
      />
      {!query?.data?.data?.length ? (
        <CardItem
          buttonText={translate("contacts.create_contact")}
          description={translate("welcome.create_contact_description")}
          icon={<UserAddOutlined />}
          title={translate("welcome.create_contact_title")}
          onClick={() => setOpenModal("contacts")}
        />
      ) : null}
      {!pipelines?.length ? (
        <CardItem
          buttonText={translate("welcome.create_pipeline_button")}
          description={translate("welcome.create_pipeline_description")}
          icon={<ApartmentOutlined />}
          title={translate("welcome.create_pipeline_title")}
          onClick={() => setOpenModal("pipelines")}
        />
      ) : null}
      {openModal == "contacts" && (
        <CreateContactModal
          open={openModal == "contacts"}
          onClose={() => setOpenModal(undefined)}
          onSuccess={() => setOpenModal(undefined)}
        />
      )}
      {openModal == "pipelines" && (
        <PipelineCreateModal
          open={openModal == "pipelines"}
          onClose={() => setOpenModal(undefined)}
        />
      )}
      {openModal == "settings" && (
        <TeamSettings
          open={openModal == "settings"}
          onClose={() => setOpenModal(undefined)}
        />
      )}
      <br />
      <Flex justify="center">
        <Button
          type="text"
          style={{ color: "green", alignSelf: "center" }}
          onClick={async () => {
            const obj = {
              ...currentTeam?.metadata?.module,
              welcome: false,
            };
            currentTeam?.account_id &&
              (await updateTeam(currentTeam?.account_id, {
                public_metadata: {
                  ...currentTeam.metadata,
                  module: {
                    ...currentTeam.metadata?.module,
                    welcome: false,
                  },
                },
              }));
            setCanAccess({ ...canAccess, personal_modules: obj });
            refetchTeams();
            list("dashboard");
          }}
        >
          {translate("welcome.do_not_show_page")}
        </Button>
      </Flex>
    </Space>
  );
}
