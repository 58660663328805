import React from "react";

import type { UseFormProps } from "@refinedev/antd";
import { useForm } from "@refinedev/antd";

import { EditOutlined } from "@ant-design/icons";
import type { FormItemProps, FormProps } from "antd";
import { Button, Form, Skeleton } from "antd";

import { useTranslate } from "@refinedev/core";
import { Text } from "../text";
import styles from "./index.module.css";

type SingleElementFormProps = {
  icon?: React.ReactNode;
  itemProps?: FormItemProps;
  extra?: React.ReactNode;
  view?: React.ReactNode;
  state?: "empty" | "form" | "view";
  onUpdate?: () => void;
  onCancel?: () => void;
  onClick?: () => void;
  loading?: boolean;
  style?: React.CSSProperties;
  useFormProps?: UseFormProps;
  formProps?: FormProps;
  helperText?: { text: string; type?: "error" | "info" };
} & React.PropsWithChildren;

export const SingleElementForm: React.FC<SingleElementFormProps> = ({
  state = "view",
  view,
  itemProps,
  onClick,
  onUpdate,
  onCancel,
  loading,
  children,
  style,
  extra,
  useFormProps,
  formProps: formPropsFromProp,
  helperText,
}) => {
  const { formProps, saveButtonProps } = useForm({
    action: "edit",
    redirect: false,
    autoSave: {
      enabled: false,
    },

    queryOptions: {
      enabled: false,
    },
    onMutationSuccess() {
      onUpdate?.();
      formProps.form?.resetFields();
    },

    mutationMode: "pessimistic",
    successNotification: formPropsFromProp && false,
    ...useFormProps,
  });
  const translate = useTranslate();
  return (
    <Form
      layout="vertical"
      {...formProps}
      {...formPropsFromProp}
      onFinish={(values) => {
        if (formPropsFromProp?.onFinish) {
          formProps.onFinish?.({});
          formPropsFromProp?.onFinish(values);

          onUpdate?.();
        } else {
          formProps.onFinish?.(values);
        }
      }}
    >
      <div className={styles.container} style={style}>
        <div className={styles.content}>
          <div className={styles.input}>
            <Text size="sm" type="secondary" className={styles.label}>
              {itemProps?.label}
            </Text>
            {loading && (
              <Skeleton.Input className={styles.skeleton} size="small" active />
            )}
            {state === "form" && !loading && (
              <div className={styles.formItem}>
                <Form.Item {...itemProps} noStyle>
                  {children}
                </Form.Item>

                {extra}
              </div>
            )}
            {state === "empty" && !loading && (
              <Button
                onClick={onClick}
                type="link"
                size="small"
                style={{ padding: 0 }}
              >
                {translate("buttons.add_something", {
                  label: itemProps?.label,
                })}
              </Button>
            )}
            {state === "view" && view}
          </div>

          {state === "form" && (
            <div className={styles.buttons}>
              <Button
                onClick={() => {
                  formProps.form?.resetFields();
                  onCancel?.();
                }}
              >
                {translate("buttons.cancel")}
              </Button>
              <Button type="primary" {...saveButtonProps}>
                {translate("buttons.save")}
              </Button>
            </div>
          )}
        </div>

        {state === "view" && (
          <div className={styles.actions}>
            <Button onClick={onClick} icon={<EditOutlined />} />
          </div>
        )}

        {helperText?.text && (
          <>
            <Text
              className={styles.helperText}
              type={helperText.type == "error" ? "danger" : "warning"}
            >
              {
                <div
                  contentEditable="false"
                  dangerouslySetInnerHTML={{ __html: helperText.text ?? "" }}
                ></div>
              }
            </Text>
          </>
        )}
      </div>
    </Form>
  );
};
