import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
export default function useSentry() {
  const { t } = useTranslation();
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn: "https://fa3cb8c0aea9b4c8f23595b18b560fee@o1271716.ingest.us.sentry.io/4507447284727808",
      integrations: [
        Sentry.browserTracingIntegration(),
        // Sentry.replayIntegration({
        //   maskAllText: false,
        //   blockAllMedia: false,
        // }),
        Sentry.feedbackIntegration({
          colorScheme: "system",
          triggerLabel: t("sentry.feedback.triggerLabel"),
          formTitle: t("sentry.feedback.formTitle"),
          nameLabel: t("sentry.feedback.nameLabel"),
          namePlaceholder: t("sentry.feedback.namePlaceholder"),
          emailLabel: t("sentry.feedback.emailLabel"),
          emailPlaceholder: t("sentry.feedback.emailPlaceholder"),
          messageLabel: t("sentry.feedback.messageLabel"),
          messagePlaceholder: t("sentry.feedback.messagePlaceholder"),
          isRequiredLabel: t("sentry.feedback.isRequiredLabel"),
          addScreenshotButtonLabel: t(
            "sentry.feedback.addScreenshotButtonLabel"
          ),
          removeScreenshotButtonLabel: t(
            "sentry.feedback.removeScreenshotButtonLabel"
          ),
          submitButtonLabel: t("sentry.feedback.submitButtonLabel"),
          cancelButtonLabel: t("sentry.feedback.cancelButtonLabel"),
          showBranding: false,
        }),
      ],
      tracesSampleRate: 0.0,
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
  return;
}
