import {
  useThemedLayoutContext,
  type RefineThemedLayoutV2HeaderProps,
} from "@refinedev/antd";
import {
  Layout as AntdLayout,
  Badge,
  Button,
  Dropdown,
  Flex,
  Grid,
  MenuProps,
  Space,
  Tag,
} from "antd";

import {
  ApartmentOutlined,
  CheckOutlined,
  LayoutOutlined,
  LogoutOutlined,
  SettingOutlined,
  StarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  useGetIdentity,
  useList,
  useLogout,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import React, { useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import useModules from "../../hooks/useModules";
import AccountShow from "../../pages/account/show";
import SearchModal from "../Layout/search";
import { Text } from "../text";

export const HeaderV2: React.FC<RefineThemedLayoutV2HeaderProps> = () => {
  const [openAccountModal, setOpenAccountModal] = useState<boolean>(false);
  const breakpoint = Grid.useBreakpoint();
  const { currentTeam, teamMenuItems, refetchTeams } = useUser();
  const { fetchModuleData } = useModules();
  const translate = useTranslate();
  const { list, replace } = useNavigation();
  const { mutate: logout } = useLogout();

  const { data: me } = useGetIdentity<any>();

  const { data: invitations } = useList({
    resource: "user_invitations",
    filters: [
      {
        field: "email",
        value: me?.email ?? "",
        operator: "eq",
      },
    ],
    meta: {
      schema: "basejump",
      select:
        "*, team:accounts!basejump_user_invitations_account_id_fkey(*), invited_by:accounts!basejump_user_invitations_invited_by_account_fkey(*)",
    },
  });

  const { setMobileSiderOpen } = useThemedLayoutContext();
  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const itemsTeam = teamMenuItems
    // ?.sort((a) => (a?.key === currentTeam.account_id ? -1 : 0))
    ?.flatMap((item: any) => {
      const currentSelected = item.key == currentTeam?.account_id;
      return [
        {
          ...item,
          icon: <ApartmentOutlined />,
          extra: currentSelected ? <CheckOutlined /> : undefined,
          label: (
            <a
              rel="noopener noreferrer"
              onClick={() => {
                if (!currentSelected) {
                  setMobileSiderOpen(false);
                  refetchTeams;
                  fetchModuleData(item.slug);
                }
              }}
            >
              {item.label}
            </a>
          ),
        },
      ];
    });

  itemsTeam?.push(
    {
      type: "divider",
    },
    {
      key: "manage_workspace",
      icon: <SettingOutlined />,
      label: (
        <a rel="noopener noreferrer">{translate("teams.manage_workspaces")}</a>
      ),
      onClick: () => replace("workspaces"),
    }
  );
  // Items in personal user dropdown

  const userMenuItems: MenuProps["items"] = [
    {
      label: translate("buttons.account", "Account settings"),
      onClick: () => setOpenAccountModal(true),
      icon: <UserOutlined />,
      key: "u",
    },
    {
      type: "divider",
    },
    {
      type: "group",
      label: translate("teams.workspaces"),
    },
    ...(itemsTeam ?? []),
    { type: "divider" },
    {
      label: translate("buttons.logout"),
      icon: <LogoutOutlined />,
      onClick: () => {
        logout();
      },
      key: "q",
    },
  ];
  if (!isMobile) {
    return (
      <AntdLayout.Header
        style={{
          background: "#fff",
          borderBottom: "1.5px solid #eee",
        }}
      >
        <Flex style={{ width: "100%" }} justify="space-between" align="center">
          {/* <Input
            style={{
              width: "40%",
              border: "none",
              background: "#f1f1f1",
              height: "40px",
            }}
            prefix={<SearchOutlined style={{ color: "#aaa" }} />}
            placeholder="Search contacts, deals, and much more..."
          /> */}
          <SearchModal autocomplete openDialog />
          <Space size={"small"}>
            <Tag style={{ display: "none" }}>
              <StarOutlined style={{ color: "orange" }} />{" "}
              {translate("settings.upgrade", "Upgrade to Pro")}
            </Tag>

            {invitations?.data?.length && invitations?.data.length > 0 ? (
              <Tag
                translate="yes"
                onClick={() => replace("workspaces")}
                color="blue"
                style={{ cursor: "pointer", borderRadius: 10 }}
              >
                You have {invitations?.data?.length}{" "}
                {invitations?.data?.length > 1 ? "invitations" : "invitation"}
              </Tag>
            ) : null}
            <Dropdown
              placement={"bottomRight"}
              menu={{ items: userMenuItems }}
              trigger={["click"]}
            >
              <Button
                type={"default"}
                //onClick={(e) => e.preventDefault()}
                shape="circle"
              >
                <Space>
                  {/* <Badge size="small" count={invitations?.data?.length}> */}
                  <Space direction={"horizontal"}>
                    <UserOutlined />

                    {/*<Avatar*/}
                    {/*style={{ backgroundColor: '#888' }}*/}
                    {/*icon={<UserOutlined />} />*/}
                  </Space>
                  {/* </Badge> */}
                </Space>
              </Button>
            </Dropdown>
          </Space>
        </Flex>
        <AccountShow
          openModal={openAccountModal}
          setOpenModal={() => setOpenAccountModal(false)}
        />
      </AntdLayout.Header>
    );
  }
  return (
    <AntdLayout.Header style={{ padding: 15, background: "#fff" }}>
      <Flex align="center">
        <Button
          type="text"
          size="large"
          onClick={() => setMobileSiderOpen(true)}
          icon={<LayoutOutlined style={{ fontSize: 23 }} />}
        />
        <Text size="md">/ {currentTeam?.name}</Text>
      </Flex>
    </AntdLayout.Header>
  );
};
