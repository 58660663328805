import React from "react";
import Icon from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import OutlookIcon from "../../../assets/icons/outlook";
import SmtpIcon from "../../../assets/icons/smtp";
import GmailIcon from "../../../assets/icons/gmail";
import StripeIcon from "../../../assets/icons/stripe";
import SlackIcon from "../../../assets/icons/slack";
import SepaIcon from "../../../assets/icons/sepa";
import LexwareIcon from "../../../assets/icons/lexware";

const useAppsList = () => {
  const translate = useTranslate();

  return [
    {
      id: "microsoft",
      title: "Microsoft 365 Outlook",
      description: translate("integrations.microsoft_mail_info"),
      icon: <Icon component={OutlookIcon} />,
      category: ["all", "email"],
      limit: 5
    },
    {
      id: "smtp",
      title: "SMTP",
      description: translate("integrations.smtp_info"),
      icon: <Icon component={SmtpIcon} />,
      category: ["all", "email"],
      limit: 5
    },
    {
      id: "sepa",
      title: "SEPA Bank account",
      description: translate("integrations.bank_account_info"),
      icon: <Icon component={SepaIcon} />,
      category: ["all", "payments"],
      limit: 1
    },
    {
      id: "lexware_public",
      title: "Lexware Office (Public API)",
      description: translate("integrations.lexware_public_info"),
      icon: <Icon component={LexwareIcon} />,
      category: ["all", "payments"],
      limit: 1
    },
    {
      id: "google",
      title: "Google",
      description: translate("integrations.google_mail_info"),
      icon: <Icon component={GmailIcon} />,
      category: ["all", "email"],
      comingSoon: true,
      limit: 5
    },
    {
      id: "stripe",
      title: "Stripe",
      description: translate("integrations.stripe_info"),
      icon: <Icon component={StripeIcon} />,
      category: ["all", "payments"],
      comingSoon: true,
      limit: 1
    },
    {
      id: "slack",
      title: "Slack",
      description: translate("integrations.slack_info"),
      icon: <Icon component={SlackIcon} />,
      category: ["all", "messaging"],
      comingSoon: true,
      limit: 1
    },
  ];
};

export default useAppsList;