import { PlusCircleFilled } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  useCreateMany,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Upload,
  UploadFile,
  theme,
} from "antd";
import { UploadProps } from "antd/lib";
import React from "react";
import CategoryAutoselect from "../../../components/shop/CategoryAutoselect";
import { useUser } from "../../../contexts/ContextProvider";
import { supabaseClient } from "../../../utility";

export default function CreateProductItem() {
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [period, setPeriod] = React.useState();
  const [productCategories, setProductCategories] = React.useState<number[]>(
    []
  );
  const [type, setType] = React.useState("item"); // New state for type
  const primaryColor = theme?.useToken()?.token?.colorPrimary;
  const translate = useTranslate();
  const { currentTeam } = useUser();

  const handleChange = ({ fileList }) =>
    setFileList(fileList.filter((file) => file.status !== "error"));
  const onRemove = async (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    setFileList(newFileList);
  };
  const dummyRequest = async (options: any) => {
    const { onSuccess } = options;

    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const props: UploadProps = {
    onChange: handleChange,
    multiple: true,
    customRequest: dummyRequest,
    onRemove: onRemove,
    listType: "picture-card",
    maxCount: 5,
  };
  const { show, list } = useNavigation();

  const { mutateAsync } = useUpdate();
  const { mutateAsync: createMany } = useCreateMany({});
  const { modalProps, formProps } = useModalForm<any>({
    resource: "product_items",
    action: "create",
    redirect: false,
    onMutationSuccess: async (data) => {
      try {
        if (!data?.data?.id) {
          console.error("no id provided");
        } else {
          let fileArr: any[] = [];
          if (fileList) {
            fileArr = await Promise.all(
              fileList.map(async (file: any) => {
                const fileName = `${Date.now()}-${file.name}`;
                const { data: fileData, error } = await supabaseClient.storage
                  .from("files")
                  .upload(
                    `teams/${currentTeam?.account_id}/shop/product_items/${data?.data?.id}/${fileName}`,
                    file.originFileObj,
                    {
                      //test
                    }
                  );
                if (error) {
                  throw error;
                } else {
                  return {
                    ...fileData,
                    name: fileName,
                  };
                }
              })
            );
          }
          setFileList([]);
          if (productCategories) {
            await createMany({
              resource: "product_item_has_product_category",
              values: productCategories.map((cat) => ({
                product_category: cat,
                product_item: data.data.id,
                account: currentTeam?.account_id,
                successNotification: false,
              })),
            });
          }
          await mutateAsync({
            resource: "product_items",
            id: data.data.id,
            values: { images: fileArr },
            successNotification: false,
          });
          show("product_items", data.data.id);
        }
      } catch (error: any) {
        //
        console.log("error", error.message);
      }
    },
    successNotification: false,
    invalidates: ["all"],
  });

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleFinish = async (values: any) => {
    try {
      if (values.product_categories) delete values.product_categories;
      formProps?.onFinish?.({
        ...values,
        images: [],
        account: currentTeam?.account_id,
      });
    } catch (error) {
      //
    }
  };
  return (
    <Modal
      {...modalProps}
      open
      centered
      title={translate("product_items.create.add_product_item")}
      width={760}
      onCancel={() => list("product_items", "replace")}
      okText={translate("product_items.buttons.add_product")}
    >
      <Form {...formProps} onFinish={handleFinish} layout="vertical">
        {/* New Type Select Field */}
        <Form.Item
          name="type"
          label="Type"
          initialValue="item"
          rules={[{ required: true }]}
        >
          <Select onChange={(value) => setType(value)}>
            <Select.Option value="item">
              {translate("product_items.create.item")}
            </Select.Option>
            <Select.Option value="issue">
              {" "}
              {translate("product_items.create.issue")}
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name={"title"}
          label={translate("product_items.fields.title")}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={translate("product_items.fields.description")}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <CategoryAutoselect
          multiple
          name="product_categories"
          onSelect={(items) => {
            setProductCategories(items);
          }}
        />

        <Form.Item
          name={"images"}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          label={translate("product_items.fields.upload_image")}
        >
          <Upload {...props} fileList={fileList}>
            {fileList.length < 6 && (
              <>
                <PlusCircleFilled
                  style={{
                    fontSize: "25px",
                    color: primaryColor,
                  }}
                />
              </>
            )}
          </Upload>
        </Form.Item>
        {type === "item" && (
          <>
            <Row gutter={10}>
              <Col span={8}>
                <Form.Item
                  name={"netto_price"}
                  initialValue={0}
                  label={translate("product_items.fields.amount")}
                >
                  <InputNumber
                    precision={2}
                    addonAfter={
                      <Form.Item name="currency" initialValue={"EUR"} noStyle>
                        <Select
                          style={{ width: 60, marginBlock: 0 }}
                          size="small"
                        >
                          <Select.Option value="USD">$</Select.Option>
                          <Select.Option value="EUR">€</Select.Option>
                          <Select.Option value="GBP">£</Select.Option>
                        </Select>
                      </Form.Item>
                    }
                    decimalSeparator=","
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  name={"sales_tax"}
                  initialValue={0}
                  label={translate("product_items.fields.sales_tax")}
                >
                  <InputNumber addonAfter="%" max={100} min={0} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={translate("product_items.fields.billing_interval")}
                  style={{ width: "100%" }}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <Form.Item name={"invoice_interval"} initialValue={1}>
                      <InputNumber
                        max={
                          period === "day"
                            ? 7
                            : period === "week"
                            ? 4
                            : period === "month"
                            ? 12
                            : 1
                        }
                        min={1}
                      />
                    </Form.Item>
                    <Form.Item
                      name={"billing_interval"}
                      noStyle
                      initialValue={"month"}
                    >
                      <Select
                        onChange={(value) => {
                          setPeriod(value);
                        }}
                      >
                        <Select.Option value="day">
                          {translate(
                            "product_items.fields.billing_interval_enum.day"
                          )}
                        </Select.Option>
                        <Select.Option value="week">
                          {translate(
                            "product_items.fields.billing_interval_enum.week"
                          )}
                        </Select.Option>
                        <Select.Option value="month">
                          {translate(
                            "product_items.fields.billing_interval_enum.month"
                          )}
                        </Select.Option>
                        <Select.Option value="year">
                          {translate(
                            "product_items.fields.billing_interval_enum.year"
                          )}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Space.Compact>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
}
