export enum BillingType {
  OneTime = "one_time",
  Start = "start",
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
  Forever = "forever",
}
export type IProduct = {
  type: "issue";
  product: number;
  name: string;
  description: string;
  netto_price: number;
  currency: string;
  variant?: any;
  sales_tax: number;
  quantity?: number;
  issues?: number;
};

export type IPhase<T = BillingType> = {
  key?: number;
  name?: string;
  title?: string;
  price?: number;
  tax_rate?: number;
  type?: "flat_fee";
  start?:
    | string
    | {
        interval: T;
        interval_count: number;
      };
  end?:
    | string
    | {
        interval: T;
        interval_count: number;
      };
  products: IProduct[] | any;
  billing_interval: {
    interval: BillingType;
    interval_count: number;
  };
};
