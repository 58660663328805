import React, { CSSProperties } from "react";

import type { UseFormProps } from "@refinedev/antd";
import { useForm } from "@refinedev/antd";

import type { FormItemProps, FormProps } from "antd";
import { Button, Divider, Form, Popconfirm, Skeleton } from "antd";

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { Text } from "../text";
import styles from "./index.module.css";

type SingleElementFormProps = {
  icon?: React.ReactNode;
  itemProps?: FormItemProps;
  extra?: React.ReactNode;
  view?: React.ReactNode;
  state?: "empty" | "form" | "view";
  onUpdate?: () => void;
  onCancel?: () => void;
  onClick?: () => void;
  onDelete?: () => void;
  loading?: boolean;
  style?: React.CSSProperties;
  useFormProps?: UseFormProps;
  formProps?: FormProps;
  helperText?: { text: string; type?: "error" | "info" };
  disableHoverBg?: boolean;
  iconStyle?: CSSProperties | undefined;
} & React.PropsWithChildren;

export const SingleElementFormV2: React.FC<SingleElementFormProps> = ({
  state = "view",
  view,
  itemProps,
  onClick,
  onUpdate,
  onCancel,
  loading,
  children,
  style,
  extra,
  useFormProps,
  formProps: formPropsFromProp,
  helperText,
  disableHoverBg,
  icon,
  iconStyle,
  onDelete,
}) => {
  const { formProps, saveButtonProps } = useForm({
    action: "edit",
    redirect: false,
    autoSave: {
      enabled: false,
    },
    queryOptions: {
      enabled: false,
    },
    onMutationSuccess() {
      onUpdate?.();
      formProps.form?.resetFields();
    },
    mutationMode: "optimistic",
    successNotification: formPropsFromProp && false,
    ...useFormProps,
  });
  const translate = useTranslate();
  return (
    <Form
      layout="vertical"
      {...formProps}
      {...formPropsFromProp}
      onFinish={(values) => {
        if (formPropsFromProp?.onFinish) {
          formProps.onFinish?.({});
          formPropsFromProp?.onFinish(values);

          onUpdate?.();
        } else {
          formProps.onFinish?.(values);
        }
      }}
    >
      <div
        className={`${styles.container} ${
          disableHoverBg && styles.containerNoBg
        }`}
        style={style}
        onClick={onClick}
      >
        <div className={styles.content}>
          {icon && (
            <div className={styles.icon} style={iconStyle}>
              {icon}
            </div>
          )}
          <div className={styles.input}>
            <Text size="sm" type="secondary" className={styles.label}>
              {itemProps?.label}
            </Text>
            {loading && (
              <Skeleton.Input className={styles.skeleton} size="small" active />
            )}
            {state === "form" && !loading && (
              <div className={styles.formItem}>
                <Form.Item {...itemProps} noStyle>
                  {children}
                </Form.Item>

                {extra}
              </div>
            )}
            {state === "empty" && !loading && (
              <Button
                onClick={onClick}
                type="link"
                size="small"
                style={{ padding: 0 }}
              >
                {translate("buttons.add_something", {
                  label: itemProps?.label,
                })}
              </Button>
            )}
            <div
              className={styles.view}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {state === "view" && view}
            </div>
          </div>

          {state === "form" && (
            <div className={styles.buttons}>
              <Button
                size="small"
                type="link"
                {...saveButtonProps}
                icon={<CheckOutlined />}
              />
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onCancel?.();
                  formProps.form?.resetFields();
                }}
                size="small"
                type="text"
                style={{ color: "#aaa" }}
                icon={<CloseOutlined />}
              />
              <Divider
                type="vertical"
                orientation="center"
                style={{ alignSelf: "center" }}
              />
              <Popconfirm
                title={translate("contacts.contacts")}
                description={translate("contacts.delete_the_property")}
                placement="leftBottom"
                onConfirm={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  onDelete?.();
                }}
                okText={translate("buttons.yes")}
                cancelText={translate("buttons.no")}
              >
                <Button
                  danger
                  type="link"
                  size="small"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </div>
          )}
        </div>

        {helperText?.text && (
          <>
            <Text
              className={styles.helperText}
              type={helperText.type == "error" ? "danger" : "warning"}
            >
              {
                <div
                  contentEditable="false"
                  dangerouslySetInnerHTML={{ __html: helperText.text ?? "" }}
                ></div>
              }
            </Text>
          </>
        )}
      </div>
    </Form>
  );
};
