const MagicIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 32 32"
    {...props}
  >
    <title>{"magic"}</title>
    <path d="m9.5 9.625-.906 2.906-.875-2.906-2.906-.906 2.906-.875.875-2.938.906 2.938 2.906.875zm5.063-1.594L14.125 9.5l-.5-1.469-1.438-.469 1.438-.438.5-1.438.438 1.438 1.438.438zM.281 24 18.187 6.625a.669.669 0 0 1 .531-.25.946.946 0 0 1 .781.281c.094.063.219.188.406.344.344.313.719.688 1 1.063.125.188.188.344.188.5.031.313-.063.594-.25.781L2.937 26.782c-.156.156-.344.219-.563.25a.946.946 0 0 1-.781-.281c-.094-.094-.219-.188-.406-.375-.344-.281-.719-.656-.969-1.063a1.476 1.476 0 0 1-.219-.531.912.912 0 0 1 .281-.781zm14.375-12.625 1.313 1.344 4.156-4.031-1.313-1.375zm-8.718 1.781-.406 1.438-.438-1.438-1.438-.469 1.438-.438.438-1.469.406 1.469 1.5.438zM20.5 12.063l.469 1.469 1.438.438-1.438.469-.469 1.438-.469-1.438-1.438-.469 1.438-.438z" />
  </svg>
);
export default MagicIcon;
