import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { Module } from "./components/Layout/module";
import { PipelineProvider } from "./contexts/PipelineProvider/usePipelineContext";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
window.addEventListener("error", function (event) {
  if (
    event.message &&
    event.message.includes("Failed to fetch dynamically imported module")
  ) {
    alert(
      "A new version of the application is available. Reloading the page..."
    );
    window.location.reload();
  }
});
root.render(
  <React.StrictMode>
    <PipelineProvider>
      <Module />
      <App />
    </PipelineProvider>
  </React.StrictMode>
);
