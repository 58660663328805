export function getContrastColor(hexColor: string) {
  // Remove '#' if present
  hexColor = hexColor?.replace("#", "");

  // Convert hex to RGB
  const r = parseInt(hexColor?.substring(0, 2), 16);
  const g = parseInt(hexColor?.substring(2, 4), 16);
  const b = parseInt(hexColor?.substring(4, 6), 16);

  // Calculate luminance (brightness)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Choose text color based on background brightness
  return brightness > 200 ? "#000000" : "#FFFFFF";
}

/**
 * Convert hex color to RGB
 * @param {string} hex - The hex color code
 * @returns {object} - The RGB representation
 */
function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

/**
 * Convert RGB color to hex
 * @param {number} r - The red component
 * @param {number} g - The green component
 * @param {number} b - The blue component
 * @returns {string} - The hex color code
 */
function rgbToHex(r, g, b) {
  return (
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}

/**
 * Lighten a color
 * @param {object} rgb - The RGB color object
 * @param {number} factor - The factor by which to lighten the color
 * @returns {object} - The lightened RGB color object
 */
function lightenColor(rgb, factor) {
  const r = Math.min(255, Math.round(rgb.r + (255 - rgb.r) * factor));
  const g = Math.min(255, Math.round(rgb.g + (255 - rgb.g) * factor));
  const b = Math.min(255, Math.round(rgb.b + (255 - rgb.b) * factor));
  return { r, g, b };
}

/**
 * Generate a light variant of the given hex color
 * @param {string} hexColor - The hex color code
 * @param {number} factor - The factor by which to lighten the color (default is 0.7)
 * @returns {string} - The hex color code of the light variant
 */
export function generateLightColor(hexColor, factor = 0.8) {
  const rgb = hexToRgb(hexColor);
  const lightRgb = lightenColor(rgb, factor);
  return rgbToHex(lightRgb.r, lightRgb.g, lightRgb.b);
}
