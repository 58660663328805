import { PlusOutlined } from "@ant-design/icons";
import { DeleteButton, EditButton, List, useTable } from "@refinedev/antd";
import { BaseRecord, useTranslate } from "@refinedev/core";
import { Button, Empty, Grid, Space, Table, Tag, Typography } from "antd";
import React, { PropsWithChildren, useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import CreateOrUpdateGroup from "./create-update-group";
import { getContrastColor } from "../../../utility";

const { useBreakpoint } = Grid;

const GroupList: React.FC<PropsWithChildren> = ({ children }) => {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { tableProps, tableQuery } = useTable({
    resource: "tags",
    syncWithLocation: false,
    meta: {
      select: "*, contacts(id)",
    },
    filters: {
      permanent: [
        {
          field: "account",
          value:
            currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
          operator: "eq",
        },
      ],
    },
  });

  const screens = useBreakpoint();

  const [openModal, setOpenModal] = useState<{
    id?: number | string;
    open: boolean;
  }>();

  return (
    <>
      <List
        title={""}
        canCreate={false}
        contentProps={{
          style: {
            maxHeight: "50dvh",
            overflowY: "auto",
            position: "relative",
          },
        }}
        headerButtons={
          tableQuery.data?.total != 0 && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setOpenModal({ open: true })}
            >
              Neue Gruppe anlegen
            </Button>
          )
        }
      >
        {tableQuery.data?.total == 0 ? (
          <Empty
            style={{ marginTop: 40, marginBottom: 40 }}
            description={
              <Space direction="vertical" size={"middle"}>
                <Typography.Text>
                  {translate("groups.you_dont_have_any_groups")}
                </Typography.Text>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => setOpenModal({ open: true })}
                >
                  {translate("groups.titles.create")}
                </Button>
              </Space>
            }
          ></Empty>
        ) : (
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="name" title={"Name"} />
            <Table.Column
              dataIndex="count"
              title={translate("tags.count")}
              render={(_, record: any) => (
                <>
                  <Tag
                    color={record.color}
                    style={{ color: getContrastColor(record.color ?? "") }}
                  >
                    {record.contacts.length}
                  </Tag>
                </>
              )}
            />

            <Table.Column
              title={translate("tags.actions")}
              dataIndex="actions"
              render={(_, record: BaseRecord) => (
                <Space>
                  <EditButton
                    hideText
                    size={screens.sm ? "small" : "middle"}
                    recordItemId={record.id}
                    onClick={() => setOpenModal({ open: true, id: record.id })}
                  />
                  <DeleteButton
                    hideText
                    size={screens.sm ? "small" : "middle"}
                    recordItemId={record.id}
                    resource="tags"
                  />
                </Space>
              )}
            />
          </Table>
        )}
      </List>
      {openModal?.open && (
        <CreateOrUpdateGroup
          id={openModal?.id}
          open={!!openModal?.open}
          onClose={() => setOpenModal({ open: false })}
        />
      )}
      {children}
    </>
  );
};
export default GroupList;
