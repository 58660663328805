import { useForm } from "@refinedev/antd";
import { BaseKey, useNavigation, useTranslate } from "@refinedev/core";
import { Form, Input, Modal } from "antd";
import React, { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../../contexts/ContextProvider";

const SubscriptionProductsCreate: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { id: prodId } = useParams();
  const { list, show } = useNavigation();
  const { formProps, saveButtonProps, onFinish } = useForm({
    resource: "subscription_products",
    action: "create",
    invalidates: ["all"],

    redirect: false,
    id: 0,
  });
  return (
    <>
      <Modal
        open
        maskClosable={false}
        title={translate("subscription_products.create.title")}
        onCancel={() => {
          show("product_items", prodId as BaseKey);
        }}
        onClose={() => {
          show("product_items", prodId as BaseKey);
        }}
        okText={translate("buttons.save")}
        okButtonProps={{
          ...saveButtonProps,
        }}
        destroyOnClose={true}
        width={760}
      >
        <Form
          {...formProps}
          layout="vertical"
          onFinish={(values) => {
            onFinish({
              ...values,
              jurisdiction: "DE",
              product_item: prodId,
              account: currentTeam?.account_id,
            }).finally(() => {
              show("product_items", prodId as BaseKey);
            });
          }}
        >
          <Form.Item
            label={translate("subscription_products.fields.title")}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translate("subscription_products.fields.description")}
            name="description"
          >
            <Input.TextArea rows={6} />
          </Form.Item>
        </Form>
      </Modal>
      {children}
    </>
  );
};

export default SubscriptionProductsCreate;
