import { MailOutlined } from "@ant-design/icons";
import {RegisterFormTypes, useRegister, useTranslate} from "@refinedev/core";
import { Button, Card, Col, Layout, Row, Space, Spin, Typography } from "antd";
import { CSSProperties, useEffect, useState } from "react";
import i18n from "i18next";

export const layoutStyles: CSSProperties = {
  background: "url('/images/authpage-login.jpg')",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "absolute",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
};

export const containerStyles: CSSProperties = {
  maxWidth: "500px",
  margin: "auto",
  padding: "32px",
  boxShadow:
    "0px 2px 4px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.03)",
  textAlign: "center",
};

function getEmailFromQuery() {
  const queryString = window.location.search;
  const urlParams = queryString.split("=")[1];
  return urlParams;
}

export default function VerifyPage() {
  const { mutate: register } = useRegister<RegisterFormTypes>();
  const [email, setEmail] = useState<string | null>();
  const translate = useTranslate();

  useEffect(() => {
    const emailParam = getEmailFromQuery();
    if (emailParam != null) {
      setEmail(decodeURIComponent(emailParam));
    }
  }, []);

  function handleResendEmail() {
    if (email) {
      register({ email });
    }
  }

  if (!email) return <Spin />;

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align={"middle"}
        style={{
          padding: "16px 0",
          minHeight: "100dvh",
          paddingTop: "16px",
        }}
      >
        <Col xs={24}>
          <Card style={containerStyles}>
            <MailOutlined style={{ fontSize: "3em" }} />
            <Typography.Title>{translate("pages.register.verify_headline")}</Typography.Title>
            <Typography.Paragraph>
              {translate("pages.register.we_have_sent_you")}{" "}
              <b style={{ whiteSpace: "nowrap" }}>{email}</b> {translate("pages.register.with_a_link")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {translate("pages.register.dont_see")}
            </Typography.Paragraph>

            <Space>
              <Button type="primary" onClick={() => handleResendEmail()}>
                {translate("pages.register.resend")}
              </Button>
              <Button type="link" href="/login">
                {translate("pages.register.back_home")}
              </Button>
            </Space>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
