const SmtpIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fontSize={32}
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="m511.402 173.657-4.872 3.077-4.017 2.478-34.697 21.879-9.572 5.982-116.911 73.326-6.41 4.017-34.013 21.365-1.795 1.11-1.88 1.198-2.735 1.709-25.895 16.237-2.392 1.624-.257.086-.256.171-9.657 6.067-.342.171-7.948-4.956-1.453-.941-.256-.171-11.025-6.922-18.118-11.366-2.051-1.282-4.358-2.735-34.014-21.365-6.41-4.017L66.66 215.448l-12.221-7.606-9.743-6.153-44.098-27.69-.598-.342.598-.341.428-.257 43.67-27.432 9.743-6.154 62.216-39.056 6.324-4.016 34.014-21.366 6.324-3.931 86.231-54.183h12.306l78.197 49.141 61.276 38.457 56.917 35.723 6.496 4.102 3.076 1.966 22.22 13.93 20.938 13.162z"
      style={{
        fill: "#c4c4c4",
      }}
    />
    <path
      d="M467.816 16.066v383.809c0 .256 0 .598-.086.853-.171 4.017-2.051 7.522-4.871 10.256-3.247 2.991-7.692 4.871-12.649 4.871H62.302c-4.957 0-9.401-1.88-12.649-4.871-3.076-2.905-4.957-6.751-4.957-11.109V16.066C44.696 7.178 52.645 0 62.302 0h387.909c9.743 0 17.605 7.178 17.605 16.066z"
      style={{
        fill: "#5eb2b2",
      }}
    />
    <path
      d="M110.759 75.071h190.508v25.378H110.759z"
      style={{
        fill: "#86ccca",
      }}
    />
    <path
      d="M110.759 71.064h190.508v25.378H110.759z"
      style={{
        fill: "#52a6a3",
      }}
    />
    <path
      d="M110.759 214.653H400.26v25.378H110.759z"
      style={{
        fill: "#86ccca",
      }}
    />
    <path
      d="M110.759 210.646H400.26v25.378H110.759z"
      style={{
        fill: "#52a6a3",
      }}
    />
    <path
      d="M110.759 284.444H400.26v25.378H110.759z"
      style={{
        fill: "#86ccca",
      }}
    />
    <path
      d="M110.759 280.437H400.26v25.378H110.759z"
      style={{
        fill: "#52a6a3",
      }}
    />
    <path
      d="M110.759 144.862H400.26v25.378H110.759z"
      style={{
        fill: "#86ccca",
      }}
    />
    <path
      d="M110.759 140.855H400.26v25.378H110.759z"
      style={{
        fill: "#52a6a3",
      }}
    />
    <path
      d="m256.299 322.104-2.991 1.709-7.264 4.273-50.594 29.57-7.093 4.188-79.906 46.662-12.649 7.349-83.41 48.714-6.41 3.675-5.384 3.161V172.803l.428.256 43.67 25.468 26.75 15.639 39.312 22.99 5.042 2.905 69.053 40.338 6.923 4.017 31.876 18.63 4.701 2.735 6.837 4.017 8.888 5.127 2.564 1.539 1.367.769 8.034 4.7z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M511.972 172.795v298.663l-.085-.074-.428-.294-.342-.22h-.086L263.706 326.496l-4.358-2.571-3.077-1.836 9.743-5.654.257-.147.256-.147 8.973-5.214z"
      style={{
        fill: "#e3e2e2",
      }}
    />
    <circle
      cx={377.114}
      cy={75.071}
      r={38.186}
      style={{
        opacity: 0.68,
        fill: "#eef3ca",
      }}
    />
    <path
      d="M511.972 425.222v45.808h-.513l-7.35.342H27.662l-.341-7.094c8.632-5.896 20.082-13.845 27.518-18.887l1.024-.684 24.956-17.178 38.97-26.92 11.281-7.777 118.365-81.615 7.947-5.556c1.88-1.195 4.016-2.222 6.324-2.904 2.735-.941 5.726-1.539 8.717-1.795 1.026-.086 1.965-.171 2.991-.086 1.025-.171 2.052-.171 3.077-.171a34.91 34.91 0 0 1 7.948.683c2.734.513 5.213 1.369 7.435 2.564l89.735 49.91 44.354 24.697 11.538 6.41 41.79 23.245 27.518 15.298c.77.428 1.624.855 2.479 1.368.171.086.428.257.684.342z"
      style={{
        fill: "#757575",
      }}
    />
    <path
      d="M512 441.408v29.997H.598v-29.997c8.973-5.469 20.767-12.905 28.459-17.519l1.025-.683 19.57-12.221 6.153-3.761 40.252-24.954 11.623-7.265 105.032-65.206 2.137-1.282 4.359-2.734 4.444-2.735 6.154-3.845 8.204-5.129c4.273-2.563 9.828-3.845 15.297-3.931a17.37 17.37 0 0 1 2.991 0 18.38 18.38 0 0 1 3.077 0c2.393 0 4.7.257 6.922.77 3.077.598 5.897 1.709 8.29 3.161l11.879 7.351 2.564 1.623 4.444 2.735 3.76 2.308 2.735 1.709 61.874 38.458 43.072 26.749 11.195 7.008 40.68 25.211 6.068 3.761 20.682 12.905c.769.427 1.538.854 2.393 1.368 5.47 3.503 12.478 7.862 19.058 11.878a176.109 176.109 0 0 0 7.009 4.27z"
      style={{
        fill: "#eaeaea",
      }}
    />
    <path
      d="M467.816 16.066v4.273c0-8.887-7.862-16.066-17.605-16.066H62.302c-9.657 0-17.605 7.178-17.605 16.066v-4.273C44.696 7.178 52.645 0 62.302 0h387.909c9.743 0 17.605 7.178 17.605 16.066z"
      style={{
        opacity: 0.2,
        fill: "#abdbdf",
      }}
    />
  </svg>
);
export default SmtpIcon;
