import React from "react";
import { Modal, Form, Input, Checkbox } from "antd";
import { useUser } from "../../../contexts/ContextProvider";
import { supabaseClient } from "../../../utility";
import { useNotification, useTranslate } from "@refinedev/core";

const AddConnectionModal = ({ visible, onClose, form, appType, refetch }) => {
  const { currentTeam } = useUser();
  const { open: notify } = useNotification();
  const translate = useTranslate();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      type ConnectionDetails = {
        account: string | undefined;
        account_type: any;
        smtp_host?: any;
        smtp_port?: any;
        smtp_user?: any;
        smtp_pass?: any;
        smtp_secure?: any;
        iban?: any;
        bic?: any;
        name?: any;
        api_key?: any;
      };

      let connectionDetails: ConnectionDetails = {
        account: currentTeam?.account_id,
        account_type: appType,
      };

      if (appType === "smtp") {
        connectionDetails = {
          ...connectionDetails,
          smtp_host: values.smtp_host,
          smtp_port: values.smtp_port,
          smtp_user: values.smtp_user,
          smtp_pass: values.smtp_pass,
          smtp_secure: values.smtp_secure,
        };
      } else if (appType === "sepa") {
        connectionDetails = {
          ...connectionDetails,
          iban: values.iban,
          bic: values.bic,
          name: values.bic,
        };
      } else if (appType === "lexware_public") {
        connectionDetails = {
          ...connectionDetails,
          api_key: values.api_key,
        };
      }

      const { data, error } = await supabaseClient.functions.invoke(
        "integration-accounts/setup",
        { body: connectionDetails }
      );

      if (error) {
        notify?.({
          description: translate("alerts.error"),
          message: translate("settings.integrations.setup_failed"),
          type: "error",
        });
      } else {
        notify?.({
          description: translate("notifications.success"),
          message: translate("settings.integrations.setup_success"),
          type: "success",
        });
        refetch();
        onClose();
      }
    } catch (err) {
      console.error("Validation Failed:", err);
      notify?.({
        description: translate("alerts.error"),
        message: translate("alerts.validation_failed"),
        type: "error",
      });
    }
  };

  const renderFields = () => {
    if (appType === "smtp") {
      return (
        <>
          <Form.Item
            label="SMTP Host"
            name="smtp_host"
            rules={[{ required: true, message: "Please enter SMTP Host" }]}
          >
            <Input placeholder="Enter SMTP Host" />
          </Form.Item>
          <Form.Item
            label="SMTP Port"
            name="smtp_port"
            rules={[{ required: true, message: "Please enter SMTP Port" }]}
          >
            <Input placeholder="Enter SMTP Port" />
          </Form.Item>
          <Form.Item
            label="SMTP User"
            name="smtp_user"
            rules={[{ required: true, message: "Please enter SMTP User" }]}
          >
            <Input placeholder="Enter SMTP User" />
          </Form.Item>
          <Form.Item
            label="SMTP Password"
            name="smtp_pass"
            rules={[{ required: true, message: "Please enter SMTP Password" }]}
          >
            <Input.Password placeholder="Enter SMTP Password" />
          </Form.Item>
          <Form.Item
            label="Secure Connection"
            name="smtp_secure"
            valuePropName="checked"
          >
            <Checkbox>Use SSL</Checkbox>
          </Form.Item>
        </>
      );
    } else if (appType === "sepa") {
      return (
        <>
          <Form.Item
            label="IBAN"
            name="iban"
            rules={[{ required: true, message: "Please enter IBAN" }]}
          >
            <Input placeholder="Enter IBAN" />
          </Form.Item>
          <Form.Item
            label="BIC"
            name="bic"
            rules={[{ required: true, message: "Please enter BIC" }]}
          >
            <Input placeholder="Enter BIC" />
          </Form.Item>
          <Form.Item
            label="Account name"
            name="name"
            rules={[{ required: true, message: "Please enter Account name" }]}
          >
            <Input placeholder="Enter your Account name" />
          </Form.Item>
        </>
      );
    } else if (appType === "lexware_public") {
      return (
        <Form.Item
          label="Public API Key"
          name="api_key"
          rules={[{ required: true, message: "Please enter API Key" }]}
        >
          <Input placeholder="Enter API Key" />
        </Form.Item>
      );
    }
    return null;
  };

  return (
    <Modal
      title="Add Connection"
      open={visible}
      onCancel={onClose}
      onOk={handleOk}
      centered
    >
      <Form form={form} layout="vertical">
        {renderFields()}
      </Form>
    </Modal>
  );
};

export default AddConnectionModal;
