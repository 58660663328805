import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { DeleteButton, useTable } from "@refinedev/antd";
import { Button, Modal, Space, Table } from "antd";
import { useState } from "react";
import ProductIssuesExportCreate from "./create";
import ProductIssuesExportEdit from "./edit";
type Props = {
  isModalVisible?: boolean;
  setIsModalVisible?: (boolean) => void;
  productId?: string;
};
export default function ProductIssuesExportList({
  isModalVisible,
  setIsModalVisible,
  productId,
}: Props) {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [idEditModalVisible, setIdEditModalVisible] = useState<number>();

  const { tableProps } = useTable({
    resource: "product_issue_exports",
    filters: {
      permanent: [
        {
          field: "product_item",
          operator: "eq",
          value: productId,
        },
      ],
    },
    pagination: {
      pageSize: 1000,
    },
  });
  return (
    <Modal
      title="Configure Export Lists"
      open={isModalVisible}
      onCancel={() => setIsModalVisible?.(false)}
      footer={null}
      width={800}
    >
      <Table
        {...tableProps}
        pagination={false}
        columns={[
          { title: "Title", dataIndex: "name", key: "id" },
          {
            render: (value) => (
              <Space>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    setIsEditModalVisible(true);
                    setIdEditModalVisible(value.id);
                  }}
                />
                <DeleteButton
                  danger
                  icon={<DeleteOutlined />}
                  recordItemId={value.id}
                  resource="product_issue_exports"
                  hideText
                />
              </Space>
            ),
          },
        ]}
        footer={() => (
          <Button
            onClick={() => setIsCreateModalVisible(true)}
            type="primary"
            icon={<PlusOutlined />}
          >
            Create
          </Button>
        )}
      />
      <ProductIssuesExportCreate
        isModalVisible={isCreateModalVisible}
        setIsModalVisible={setIsCreateModalVisible}
        productId={productId}
      />
      {idEditModalVisible && isEditModalVisible && (
        <ProductIssuesExportEdit
          isModalVisible={isEditModalVisible}
          setIsModalVisible={setIsEditModalVisible}
          exportId={idEditModalVisible}
        />
      )}
    </Modal>
  );
}
