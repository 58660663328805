import { UserOutlined } from "@ant-design/icons";
import { List, useForm } from "@refinedev/antd";
import {
  useGetIdentity,
  useGetLocale,
  useNotification,
  useSetLocale,
  useTranslate,
} from "@refinedev/core";
import {
  Avatar,
  Divider,
  Form,
  Input,
  MenuProps,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
} from "antd";
import Link from "antd/es/typography/Link";
import "moment-timezone";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "../../components";
import CustomBreadcrumb from "../../components/common/CustomBreadcrumb";
import { useUser as useTeam } from "../../contexts/ContextProvider";
import { useUser } from "../../hooks/useUser";
import { supabaseClient } from "../../utility";
import { useBuildTimezones } from "../../utility/accountGeneral"; // Passe den Pfad deines Hooks an
type Props = {
  openModal: boolean;
  setOpenModal: () => void;
};
const AccountShow: React.FC<Props> = ({ openModal, setOpenModal }) => {
  const timezones = useBuildTimezones();
  const { open } = useNotification();
  const {
    updateTeam,
    personalAccount,
    fetchPersonalAccount,
    fetchTeamMembers,
  } = useTeam();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error]: [any, any] = useState(null);
  const [messages, setMessages] =
    useState<
      Partial<
        Record<"email" | "password", { text: string; type?: "error" | "info" }>
      >
    >();

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const { formProps: formPropsPassword, saveButtonProps } = useForm({
    action: "edit",
    redirect: false,
    mutationMode: "pessimistic",
    warnWhenUnsavedChanges: false,
  });
  const { formProps, form } = useForm({
    action: "edit",
    redirect: false,
    mutationMode: "pessimistic",
    warnWhenUnsavedChanges: false,
  });
  const showPasswordModal = () => {
    setIsPasswordModalOpen(true);
  };

  const handlePasswordModalCancel = () => {
    setIsPasswordModalOpen(false);
  };

  const getLocale = useGetLocale();
  const currentLocale = getLocale();
  const changeLanguage = useSetLocale();
  const { i18n } = useTranslation();
  const translate = useTranslate();
  const { data: me, refetch: refetchMe } = useGetIdentity<any>();
  const { updateAuth } = useUser();
  const menuItems: MenuProps["items"] = [...(i18n.languages || [])]
    .sort()
    .map((lang: string) => ({
      key: lang,
      onClick: () => changeLanguage(lang),
      icon: (
        <span style={{ marginRight: 8 }}>
          <Avatar size={16} src={`/images/flags/${lang}.svg`} />
        </span>
      ),
      label:
        lang === "en"
          ? translate("page.settings.english")
          : translate("page.settings.german"),
    }));

  if (!me && personalAccount != undefined) {
    return <Spin />;
  }
  const ojbSelectLang = menuItems.map((item: any) => {
    return { label: item.label, value: item.key };
  });

  async function onChange(value) {
    const obj = {
      ...personalAccount?.metadata,
      timezone: value,
    };
    const data = await updateTeam(personalAccount?.account_id, {
      public_metadata: {
        ...obj,
      },
    });
    if (data?.account_id) {
      open?.({
        description: translate("notifications.success"),
        message: translate("notifications.editSuccess"),
        type: "success",
      });
    }

    if (!personalAccount) {
      return <Spin />;
    }
  }
  const breadcrumbItems = [
    {
      icon: <UserOutlined />,
      title: translate("account.title"),
    },
  ];

  return (
    <Modal
      open={openModal}
      width={800}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      onClose={() => setOpenModal()}
      onCancel={() => setOpenModal()}
    >
      <div style={{ margin: "0 auto" }}>
        <List
          breadcrumb={false}
          title={<CustomBreadcrumb items={breadcrumbItems} />}
          canCreate={false}
        >
          <Divider orientation="left">
            {translate("account.personal_details")}
          </Divider>
          <Form
            form={form}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            style={{ maxWidth: 720 }}
            {...formProps}
            onFinish={async (values: any) => {
              if (
                values.firstname != personalAccount?.metadata?.firstname ||
                values.lastname != personalAccount?.metadata?.lastname
              ) {
                const obj = {
                  ...personalAccount?.metadata,
                  firstname: values?.firstname,
                  lastname: values.lastname,
                };
                const data = await updateTeam(personalAccount?.account_id, {
                  name: `${values?.firstname} ${values?.lastname}`,
                  public_metadata: {
                    ...obj,
                  },
                });
                if (data?.account_id) {
                  fetchPersonalAccount();
                  fetchTeamMembers();
                  open?.({
                    description: translate("notifications.success"),
                    message: translate("notifications.editSuccess"),
                    type: "success",
                  });
                }
                return {};
              } else if (values.email != me?.email) {
                const data: any = await updateAuth({ email: values.email });
                if (!data?.error) {
                  open?.({
                    description: translate("notifications.success"),
                    message: translate("notifications.editSuccess"),
                    type: "success",
                  });
                  refetchMe();
                } else {
                  open?.({
                    description: translate("notifications.error"),
                    message: translate("notifications.editError"),
                    type: "error",
                  });
                }
              }
            }}
          >
            <Form.Item
              label={translate("account.first")}
              name={"firstname"}
              initialValue={personalAccount?.metadata?.firstname}
            >
              <Input
                onBlur={() => {
                  form.submit();
                }}
              />
            </Form.Item>
            <Form.Item
              label={translate("account.last")}
              name={"lastname"}
              initialValue={personalAccount?.metadata?.lastname}
            >
              <Input
                onBlur={() => {
                  form.submit();
                }}
              />
            </Form.Item>
            <Form.Item
              label={translate("account.email")}
              name={"email"}
              initialValue={me?.email}
              help={
                messages?.email ?? me?.new_email ? (
                  <div style={{ marginBottom: 20 }}>
                    <Text type="secondary">
                      {translate("users.email_not_confirmed_yet", {
                        email: me?.new_email ?? "",
                      })}
                    </Text>
                    <Text type="secondary">
                      Cancel the request?
                      <Popconfirm
                        title={translate("user.cancel_the_request_popup.title")}
                        description={translate(
                          "user.cancel_the_request_.description"
                        )}
                        placement="leftBottom"
                        onConfirm={async () => {
                          const { error } = await supabaseClient.rpc(
                            "cancel_email_change"
                          );
                          await updateAuth({});
                          form.setFieldValue("email", me?.email);
                          if (!error) {
                            open?.({
                              description: translate("notifications.success"),
                              message: translate("notifications.editSuccess"),
                              type: "success",
                            });
                            refetchMe();
                          } else {
                            open?.({
                              description: translate("notifications.error"),
                              message: translate("notifications.editError"),
                              type: "error",
                            });
                          }
                        }}
                        okText={translate("buttons.yes")}
                        cancelText={translate("buttons.no")}
                      >
                        <Link> Click here. </Link>
                      </Popconfirm>
                      You did not receive an E-Mail?
                      <Popconfirm
                        title={translate("user.cancel_the_request_popup.title")}
                        description={translate(
                          "user.cancel_the_request_.description"
                        )}
                        placement="leftBottom"
                        onConfirm={async () => {
                          form.submit();
                          if (!error) {
                            open?.({
                              description: translate("notifications.success"),
                              message: translate("notifications.editSuccess"),
                              type: "success",
                            });
                            refetchMe();
                          } else {
                            open?.({
                              description: translate("notifications.error"),
                              message: translate("notifications.editError"),
                              type: "error",
                            });
                          }
                        }}
                        okText={translate("buttons.yes")}
                        cancelText={translate("buttons.no")}
                      >
                        <Link> Resend here</Link>
                      </Popconfirm>
                    </Text>
                  </div>
                ) : undefined
              }
            >
              <Input
                onBlur={() => {
                  form.submit();
                }}
              />
            </Form.Item>
          </Form>
          <Form
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            style={{ maxWidth: 720 }}
          >
            <Form.Item label={translate("account.password")}>
              <Space>
                <Form.Item name="password" noStyle>
                  <Input
                    disabled
                    style={{ width: 160 }}
                    placeholder="●●●●●●●●"
                  />
                </Form.Item>
                <Typography.Link onClick={showPasswordModal}>
                  {translate("account.change_password")}
                </Typography.Link>
                <Modal
                  title={translate("account.change_password")}
                  open={isPasswordModalOpen}
                  okButtonProps={{
                    ...saveButtonProps,
                  }}
                  onCancel={handlePasswordModalCancel}
                >
                  <Form
                    {...formPropsPassword}
                    layout="vertical"
                    onFinish={async (value: any) => {
                      if (
                        value.newPassword !== value.repeatPassword ||
                        value.newPassword == "" ||
                        value.repeatPassword == "" ||
                        value.newPassword == undefined ||
                        value.repeatPassword == undefined
                      ) {
                        open?.({
                          description: "Error",
                          message: translate(
                            "validation.passwords_do_not_match"
                          ),
                          type: "error",
                        });

                        return {};
                      }
                      setMessages(() =>
                        messages?.email ? { email: messages?.email } : undefined
                      );
                      const data: any = await updateAuth({
                        password: value.newPassword,
                      });
                      if (data?.data?.user != null) {
                        handlePasswordModalCancel();
                        open?.({
                          description: translate("notifications.success"),
                          message: translate("notifications.editSuccess"),
                          type: "success",
                        });
                      } else {
                        open?.({
                          description: data?.error?.name,
                          message: data?.error?.message,
                          type: "error",
                        });
                      }
                      refetchMe();
                    }}
                  >
                    <Form.Item
                      name={"newPassword"}
                      label={"New Password"}
                      rules={[
                        {
                          min: 8,
                          message: translate("validation.password_min_length", {
                            min: 8,
                          }),
                        },
                        {
                          pattern: /(?=.*[a-z])(?=.*[A-Z])/,
                          message: translate(
                            "validation.password_upper_and_lowercase"
                          ),
                        },
                        {
                          pattern: /(?=.*\d)/,
                          message: translate(
                            "validation.password_at_least_one_number"
                          ),
                        },
                      ]}
                    >
                      <Input type="password" placeholder="********" />
                    </Form.Item>
                    <Form.Item
                      name={"repeatPassword"}
                      label={"Repeat Password"}
                      style={{ marginBottom: 0 }}
                    >
                      <Input type="password" placeholder="********" />
                    </Form.Item>
                  </Form>
                </Modal>
              </Space>
            </Form.Item>
          </Form>
          <Divider orientation="left">
            {translate("account.preferences")}
          </Divider>
          <Form
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            style={{ maxWidth: 720 }}
          >
            <Form.Item label={translate("account.language")}>
              <Select
                options={ojbSelectLang}
                defaultValue={currentLocale}
                onSelect={async (lang) => {
                  changeLanguage(lang);
                  const obj = {
                    ...personalAccount?.metadata,
                    language: lang,
                  };
                  await supabaseClient.auth.updateUser({
                    data: { language: lang },
                  });
                  open?.({
                    description: translate("notifications.success"),
                    message: translate("notifications.editSuccess"),
                    type: "success",
                  });
                }}
              />
            </Form.Item>
            <Form.Item label={translate("account.timezone")}>
              <Select
                showSearch
                placeholder="Select a timezone"
                optionFilterProp="children"
                onChange={onChange}
                defaultValue={personalAccount?.metadata?.timezone ?? null}
                filterOption={(input, option) => {
                  const children = option?.children as string | undefined;
                  return (
                    children?.toLowerCase().includes(input.toLowerCase()) ||
                    false
                  );
                }}
              >
                {timezones.map((tz) => (
                  <Select.Option key={tz.id} value={tz.id}>
                    {tz.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={translate("account.timeformat")}
              valuePropName="checked"
            >
              <Space>
                <Switch
                  defaultValue={personalAccount?.metadata?.timeformat24}
                  onChange={async (value) => {
                    const obj = {
                      ...personalAccount?.metadata,
                      timeformat24: value,
                    };
                    const data = await updateTeam(personalAccount?.account_id, {
                      public_metadata: {
                        ...obj,
                      },
                    });
                    if (data?.account_id) {
                      open?.({
                        description: translate("notifications.success"),
                        message: translate("notifications.editSuccess"),
                        type: "success",
                      });
                    }
                  }}
                />
                <Text>{translate("account.format24h")}</Text>
              </Space>
            </Form.Item>
          </Form>
        </List>
      </div>
    </Modal>
  );
};
export default AccountShow;
