import moment from "moment";
import "moment-timezone";
import { useTranslate } from "@refinedev/core";

export const useBuildTimezones = () => {
    const translate = useTranslate();

    const buildTZ = (id) => {
        const momentTz = moment.tz(id);
        const offset = momentTz.utcOffset();
        // Holt den Namen der Zeitzone aus der Übersetzungsdatei, falls nicht vorhanden, nimmt er den Standardnamen
        const places = translate(`timezones.${id}`, { fallback: id });
        const name = `${id}${places && " - " + places}`;
        const gmt = `GMT${offset ? momentTz.format("Z") : ""}`;
        const fullName = `${gmt} - ${name}`;
        return { id, fullName, gmt, offset };
    };

    // Hier beschränkst du die Liste auf die gängigen Zeitzonen
    const commonTimezones = [
        "Pacific/Midway", "Pacific/Honolulu", "America/Juneau", "America/Dawson",
        "America/Boise", "America/Phoenix", "America/Chicago", "America/Regina",
        "America/Mexico_City", "America/Detroit", "America/Bogota", "America/Caracas",
        "America/Santiago", "America/Sao_Paulo", "Europe/London", "Europe/Berlin",
        "Europe/Athens", "Asia/Dubai", "Asia/Kolkata", "Asia/Shanghai", "Asia/Tokyo",
        "Australia/Sydney", "Pacific/Auckland"
    ];

    return commonTimezones
        .map((id) => buildTZ(id))
        .sort((a, b) => a.offset - b.offset)
        .concat({
            id: null,
            fullName: `${translate("account.let_my_browser_decide")}${buildTZ(moment.tz.guess()).gmt}`,
            gmt: "",
            offset: 0
        });
};
