import { MenuProps } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ITeam } from "../interfaces/team.interface";
import { supabaseClient } from "../utility";

function useTeam() {
  const { teamSlug } = useParams();
  const navigate = useNavigate();
  // const location = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);
  const [teamData, setTeamData] = useState<any>(undefined);
  const [currentTeamData, setCurrentTeamData] = useState<ITeam | undefined>(
    undefined
  );
  const [teamMenuItems, setTeamMenuItems] = useState<MenuProps["items"]>([]);
  const [teamMembersData, setTeamMembersData] = useState<any>([]);
  const [openInvitationsData, setOpenInvitationsData] =
    useState<any>(undefined);
  const [personalAccount, setPersonalAccount] = useState<any>(undefined);
  useEffect(() => {
    if (firstLoad) setFirstLoad(false);
  }, [firstLoad]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabaseClient.rpc("get_accounts");
        if (error) return null;
        setTeamData(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (!firstLoad) fetchData();
  }, [firstLoad]);

  const refetchTeams = useCallback(async () => {
    if (teamSlug) {
      try {
        const { data, error } = await supabaseClient.rpc("get_accounts");
        if (error) return null;
        setTeamData(data);
        setCurrentTeamData(
          data.filter((a: any) => a.slug === teamSlug)[0] || null
        );

        setTeamMenuItems(
          data
            .sort((a: any, b: any) => a.name.localeCompare(b.name))
            .filter((a) => !a.personal_account)
            .map((account: any) => ({
              key: account.account_id,
              onClick: () => navigate(account.slug),
              label: account.name,
              slug: account.slug,
            }))
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data, error } = await supabaseClient.rpc("get_accounts");
        if (error) return null;
        setTeamData(data);

        setTeamMenuItems(
          data
            .sort((a: any, b: any) => a.name.localeCompare(b.name))
            .filter((a) => !a.personal_account)
            .map((account: any) => ({
              key: account.account_id,
              onClick: () => navigate(account.slug),
              label: account.name,
              slug: account.slug,
            }))
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [navigate, teamSlug]);
  useEffect(() => {
    refetchTeams();
  }, [refetchTeams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabaseClient.rpc(
          "get_account_invitations",
          {
            account_id: currentTeamData?.account_id,
          }
        );
        if (error) throw error;
        setOpenInvitationsData(data);
      } catch (error) {
        console.log("error on get_account_invitations", error);
      }
    };
    if (currentTeamData?.account_id && !firstLoad) {
      fetchData();
    }
  }, [currentTeamData, currentTeamData?.account_id, firstLoad]);

  const updateTeam = async (
    account_id: string,
    values: Omit<ITeam, "account_id">
  ) => {
    try {
      const { data, error } = await supabaseClient.rpc("update_account", {
        account_id: account_id,
        ...values,
      });
      if (error) throw error;
      return data;
    } catch (error) {
      console.log("update_account error", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabaseClient.rpc(
          "get_personal_account"
        );

        if (error) throw error;
        setPersonalAccount(data);
      } catch (error) {
        console.log(error);
      }
    };
    if (!firstLoad) fetchData();
  }, [firstLoad]);

  const fetchPersonalAccount = useCallback(async () => {
    try {
      const { data, error } = await supabaseClient.rpc("get_personal_account");

      if (error) throw error;
      setPersonalAccount(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchTeamMembers = useCallback(async () => {
    try {
      if (currentTeamData?.account_id && !firstLoad) {
        const { data, error } = await supabaseClient.rpc(
          "get_account_members",
          {
            account_id: currentTeamData?.account_id,
          }
        );

        if (error) throw error;
        setTeamMembersData(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentTeamData?.account_id, firstLoad]);

  useEffect(() => {
    fetchPersonalAccount();
  }, [fetchPersonalAccount]);

  useEffect(() => {
    fetchTeamMembers();
  }, [fetchTeamMembers]);

  function getTeamMember(userId) {
    return teamMembersData.find((member) => member.user_id == userId);
  }
  return {
    currentTeam: currentTeamData,
    allTeams: teamData,
    teamMenuItems: teamMenuItems,
    teamMembers: teamMembersData,
    invitetMember: openInvitationsData,
    updateTeam,
    refetchTeams,
    personalAccount,
    fetchPersonalAccount,
    fetchTeamMembers,
    getTeamMember,
  };
}

export default useTeam;
