import { List, useTable } from "@refinedev/antd";
import {
  BaseKey,
  useDeleteMany,
  useList,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Button, Popconfirm, Table, Tabs, TabsProps } from "antd";
import React, { PropsWithChildren } from "react";
import { useUser } from "../../../contexts/ContextProvider";

const countStyle = {
  marginInline: "5px",
  background: "lightblue",
  paddingInline: "10px",
  borderRadius: "16px",
};

const SubscriptionsList: React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  // const [selectedStatus, setSelectedStatus] = React.useState<
  //   "active" | "scheduled" | "canceled" | "all"
  // >("active");

  const translate = useTranslate();
  const { currentTeam } = useUser();

  const { show } = useNavigation();

  const { data } = useList({
    filters: [
      {
        field: "account",
        value:
          currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
        operator: "eq",
      },
    ],
  });
  const counts = React.useMemo(
    () =>
      data?.data.reduce((acc, { status }, count) => {
        acc[status] = count + 1;
        return acc;
      }, {}),
    [data?.data]
  );

  const { tableProps, filters, setFilters } = useTable({
    meta: { select: "*, invoice_contact(*)" },
    syncWithLocation: true,
    filters: {
      permanent: [
        {
          field: "account",
          value:
            currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
          operator: "eq",
        },
      ],
      initial: [{ field: "status", value: "active", operator: "eq" }],
    },
  });

  const tabs: TabsProps["items"] = [
    {
      key: "active",
      label: (
        <span>
          <span>{translate("subscriptions.status.active")}</span>
          <span style={countStyle}>{counts && counts["active"]}</span>
        </span>
      ),
    },
    {
      key: "upcoming",
      label: (
        <span>
          <span>{translate("subscriptions.status.upcoming")}</span>
          <span style={countStyle}>{(counts && counts["upcoming"]) ?? 0}</span>
        </span>
      ),
    },
    {
      key: "canceled",
      label: (
        <span>
          <span>{translate("subscriptions.status.canceled")}</span>
          <span style={countStyle}>{(counts && counts["canceled"]) ?? 0}</span>
        </span>
      ),
    },
    {
      key: "all",
      label: (
        <span>
          <span>{translate("subscriptions.status.all")}</span>
          <span style={countStyle}>{data?.total ?? 0}</span>
        </span>
      ),
    },
  ];
  const statusFilter = filters.find((filter: any) => filter.field === "status");

  const onChange = (key: any) => {
    setFilters([
      {
        field: "status",
        operator: "eq",
        value: key == "all" ? undefined : key,
      },
    ]);
  };
  const { mutate } = useDeleteMany();
  const handleDeleteBulk = () => {
    mutate({
      resource: "subscriptions",
      ids: selectedRowKeys as BaseKey[],
    });
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <List
      title={translate("subscriptions.list.title")}
      headerButtons={({ defaultButtons }) => (
        <>
          {selectedRowKeys.length != 0 && (
            <Popconfirm
              title={translate("subscriptions.buttons.delete.title")}
              description={translate(
                "subscriptions.buttons.delete.description"
              )}
              onConfirm={handleDeleteBulk}
              okText={translate("buttons.yes", "Yes")}
              cancelText={translate("buttons.no", "No")}
            >
              <Button type="primary" danger>
                {translate("subscriptions.buttons.delete.selected")}
              </Button>
            </Popconfirm>
          )}
          {defaultButtons}
        </>
      )}
    >
      <Tabs
        type="card"
        className="pipelines"
        items={tabs}
        defaultActiveKey={"active"}
        activeKey={(statusFilter?.value as string) ?? "all"}
        onChange={onChange}
      ></Tabs>
      <Table
        {...tableProps}
        rowKey={(record) => record.id ?? ""}
        rowClassName={"clickableRow"}
        onRow={(record) => {
          return {
            onClick: () => {
              record.id && show("subscriptions", record.id);
            },
          };
        }}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        rowSelection={rowSelection}
      >
        <Table.Column
          dataIndex={"invoice_contact"}
          title={translate("subscriptions.list.customer")}
          render={(value) => value?.email}
        />
        <Table.Column
          dataIndex={"name"}
          title={translate("subscription_products.show.title")}
        />
        {/* <Table.Column
          dataIndex={"phases"}
          title={translate("subscriptions.list.neto_price")}
          render={(value) =>
            `€ ${calculatePhaseSum(value)} / ${translate(
              "subscriptions.list.monthly"
            )}`
          }
        /> */}
        {/* <Table.Column
          dataIndex={"phases"}
          title={translate("subscriptions.list.duration")}
          render={(value) => {
            const res = getFirstAndLastDates(value);
            return `${new Date(res.firstDate).toLocaleDateString()}- ${
              res.lastDate == "forever"
                ? "Forever"
                : new Date(res.lastDate).toLocaleDateString()
            }
            `;
          }}
        /> */}
      </Table>
      {children}
    </List>
  );
};

export default SubscriptionsList;
