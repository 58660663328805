import { gray } from "@ant-design/colors";
import { MailOutlined, WarningFilled } from "@ant-design/icons";
import { useSelect } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Button, Card, Checkbox, Flex, Form, Input, Select, Space } from "antd";
import mjml2html from "mjml-browser";
import React, { useContext, useEffect, useState } from "react";
import {
  BuilderContext,
  INode,
  NodeContext,
  useDrawer,
} from "react-flow-builder";
import { useUser } from "../../../contexts/ContextProvider";
import { supabaseClient } from "../../../utility";
import { findParentNode } from "../../../utility/automations";
import { Text } from "../../text";

export const SendMailDisplay: React.FC = () => {
  const node = useContext(NodeContext);

  function handleMJMLConvert(value: any) {
    try {
      const htmlOutput = mjml2html(value ?? "").html;
      return (
        <div
          style={{ maxHeight: 300, overflow: "auto" }}
          dangerouslySetInnerHTML={{ __html: htmlOutput }}
        />
      );
    } catch (error) {
      console.error("MJML Parsing Error:", error);
      return (
        <div style={{ color: "red" }}>
          <p>Error parsing email content. Please ensure the MJML is valid.</p>
        </div>
      );
    }
  }
  return (
    <>
      <Card
        size={"small"}
        title={
          <Flex justify={"space-between"}>
            <Space>
              <MailOutlined />
              <Text>Send Mail</Text>
            </Space>
            {node.validateStatusError ? (
              <WarningFilled style={{ color: "orange" }} />
            ) : null}
          </Flex>
        }
        style={{ width: 300 }}
      >
        <p>
          {node.data?.from ? (
            <>
              <Text size={"xs"} style={{ color: gray[4] }}>
                From
              </Text>
              <br /> <Text strong>{node.data?.from}</Text>
            </>
          ) : null}
          {node.data?.cc ? (
            <>
              <br />
              <Text size={"xs"} style={{ color: gray[4] }}>
                CC
              </Text>
              <br /> <Text strong>{node.data?.cc}</Text>
            </>
          ) : null}
          {node.data?.bcc ? (
            <>
              <br />
              <Text size={"xs"} style={{ color: gray[4] }}>
                BCC
              </Text>
              <br /> <Text strong>{node.data?.bcc}</Text>
            </>
          ) : null}
          {node.data?.subject ? (
            <>
              <br />
              <Text size={"xs"} style={{ color: gray[4] }}>
                Subject
              </Text>
              <br /> <Text strong>{node.data?.subject}</Text>
            </>
          ) : null}
          {node.data?.message ? (
            <>
              <br />
              <Text size={"xs"} style={{ color: gray[4] }}>
                Message
              </Text>
              <br />
              {handleMJMLConvert(node.data?.message)}
            </>
          ) : null}
        </p>
      </Card>
    </>
  );
};

const mjmlRegex = /^<mjml>\s*<mj-body>[\s\S]*<\/mj-body>\s*<\/mjml>$/;

export const SendMailForm: React.FC<{ nodes?: INode[] }> = ({ nodes }) => {
  const { selectedNode: node, nodes: familyNodes } = useContext(BuilderContext);
  const [options, setOptions] = React.useState<{ value: any; label: any }[]>(
    []
  );
  const { closeDrawer: cancel, saveDrawer: save } = useDrawer();
  const { currentTeam } = useUser();
  const [showCcBcc, setShowCcBcc] = useState(false); // State to control the visibility of CC and BCC
  const [form] = Form.useForm();
  const isOnIteration = findParentNode(familyNodes, node)?.type == "iterator";
  const { selectProps } = useSelect({
    resource: "email_sender_addresses",
    optionLabel: "email",
    optionValue: "email",
    filters: [
      {
        field: "account",
        operator: "eq",
        value: currentTeam?.account_id,
      },
    ],
  });
  const handleSubmit = async () => {
    const emailMessage = form.getFieldValue("message") ?? "".trim();
    try {
      if (
        !emailMessage ||
        /^<mjml><mj-body><\/mj-body><\/mjml>$/.test(
          emailMessage.replace(/\s+/g, "")
        )
      ) {
        throw Error();
      }
      if (!mjmlRegex.test(emailMessage))
        form.setFieldValue(
          "message",
          `<mjml><mj-body>${emailMessage}</mj-body></mjml>`
        );
      const values = await form.validateFields();
      save?.(values);
    } catch (error) {
      if (!mjmlRegex.test(emailMessage))
        form.setFieldValue(
          "message",
          `<mjml><mj-body>${emailMessage}</mj-body></mjml>`
        );
      const values = form.getFieldsValue();
      save?.(values, !!error);
    }
  };
  const translate = useTranslate();

  const getTableColumns = async () => {
    const data = await supabaseClient.rpc("get_contacts_table_columns", {
      p_account: currentTeam?.account_id,
    });
    const formFields = data?.data?.map((item) => ({
      value: `contacts.${item.column_name}`,
      label: item.column_name
        .replace(/_/g, " ")
        .replace(/\b\w/g, (c) => c.toUpperCase()),
    }));
    setOptions(formFields);
  };

  useEffect(() => {
    if (
      nodes?.filter((n) => n.type === "getcontact") !== undefined &&
      nodes?.filter((n) => n.type === "getcontact").length > 0 &&
      options.length === 0
    ) {
      getTableColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showCcBcc) {
      form.setFieldsValue({ cc: "", bcc: "" }); // Clear CC and BCC fields when hidden
    }
  }, [showCcBcc, form]);

  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        initialValues={node?.data || { name: node?.name }}
      >
        <Form.Item name="from" label="From" rules={[{ required: true }]}>
          <Select {...selectProps} />
        </Form.Item>
        <Form.Item
          name="to"
          label="To"
          extra={
            <>
              <Button
                size="small"
                type="link"
                onClick={() => setShowCcBcc(!showCcBcc)}
              >
                {showCcBcc ? "Hide CC/BCC" : "Show CC/BCC"}
              </Button>
            </>
          }
          rules={[{ required: !isOnIteration }]}
        >
          <Select
            mode="tags"
            placeholder={"Wird aus Kontakt automatisch übernommen"}
            open={false}
            suffixIcon={null}
            style={{ width: "100%" }}
          />
        </Form.Item>
        {showCcBcc && (
          <>
            <Form.Item name="cc" label="CC" rules={[{ type: "email" }]}>
              <Input />
            </Form.Item>
            <Form.Item name="bcc" label="BCC" rules={[{ type: "email" }]}>
              <Input />
            </Form.Item>
          </>
        )}
        <Form.Item name="subject" label="Subject" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="message" label="Message" rules={[{ required: true }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="onNull"
          style={{ width: "100%", flex: 1 }}
          colon={false}
          label={null}
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox>
            Don't send the email if there is no contacts selected.
          </Checkbox>
        </Form.Item>
      </Form>
      <div>
        <Space>
          <Button onClick={cancel}>{translate("buttons.cancel")}</Button>
          <Button type="primary" onClick={handleSubmit}>
            {translate("buttons.save")}
          </Button>
        </Space>
      </div>
    </div>
  );
};
