import { PlusOutlined } from "@ant-design/icons";
import { DeleteButton, EditButton, List, useTable } from "@refinedev/antd";
import { BaseRecord, useTranslate } from "@refinedev/core";
import { Button, Grid, Space, Table, Typography } from "antd";
import { Empty } from "antd/lib";
import { useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import CreateOrUpdate from "./create-update-segment";

const { useBreakpoint } = Grid;

export default function SegmentList() {
  const [openModal, setOpenModal] = useState<{
    id?: number | string;
    open: boolean;
  }>();
  const translate = useTranslate();
  const screens = useBreakpoint();
  const { currentTeam } = useUser();

  const { tableProps, tableQuery } = useTable({
    resource: "segments",
    syncWithLocation: false,
    meta: {
      select: "*",
    },

    filters: {
      permanent: [
        {
          field: "account",
          value: currentTeam?.account_id,
          operator: "eq",
        },
      ],
    },
  });

  return (
    <>
      <List
        title=""
        canCreate={false}
        contentProps={{
          style: {
            maxHeight: "50dvh",
            overflowY: "auto",
            position: "relative",
          },
        }}
        headerButtons={
          tableQuery.data?.total != 0 && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setOpenModal({ open: true })}
            >
              {translate("segments.titles.create")}
            </Button>
          )
        }
      >
        {tableQuery.data?.total == 0 ? (
          <Empty
            style={{ marginTop: 40, marginBottom: 40 }}
            description={
              <Space direction="vertical" size={"middle"}>
                <Typography.Text>
                  {translate("segments.you_dont_have_any_segments")}
                </Typography.Text>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => setOpenModal({ open: true })}
                >
                  {translate("segments.titles.create")}
                </Button>
              </Space>
            }
          ></Empty>
        ) : (
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="name" title={"Name"} />
            <Table.Column
              title={translate("tags.actions")}
              dataIndex="actions"
              width={200}
              render={(_, record: BaseRecord) => (
                <Space>
                  <EditButton
                    hideText
                    size={screens.sm ? "small" : "middle"}
                    recordItemId={record.id}
                    onClick={() => setOpenModal({ open: true, id: record.id })}
                  />
                  <DeleteButton
                    hideText
                    size={screens.sm ? "small" : "middle"}
                    recordItemId={record.id}
                    resource="segments"
                  />
                </Space>
              )}
            />
          </Table>
        )}
      </List>
      {openModal?.open && (
        <CreateOrUpdate
          {...openModal}
          onClose={() => setOpenModal({ open: false })}
        />
      )}
    </>
  );
}
