import { useSelect } from "@refinedev/core";
import { Typography } from "antd";
import React from "react";

type Props = {
  data: any;
};

export default function InfoAutomation({ data }: Props) {
  const { query: queryResult } = useSelect({
    resource: "tags",
    filters: [
      {
        field: "id",
        operator: "in",
        value: [...(data?.criterias?.groups ?? [])],
      },
    ],
  });

  const getFrequencyDescription = () => {
    switch (data?.frequency?.type) {
      case "daily":
        return `every day at ${data?.frequency?.time}`;
      case "weekly":
        return `every ${
          data?.frequency?.interval
        } week(s) on ${data?.frequency?.daysOfWeek?.join(", ")}`;
      case "monthly":
        return `every ${data?.frequency?.interval} month(s) on day ${data?.frequency?.day}`;
      case "yearly":
        return `every year on ${data?.frequency?.month} ${data?.frequency?.day}`;
      default:
        return "a custom frequency";
    }
  };

  const getWhenToStartDescription = () => {
    switch (data?.frequency?.whenToStart) {
      case "scheduled":
        return `on a scheduled basis, ${getFrequencyDescription()}`;
      case "certain_date":
        return `on ${new Date(
          parseInt(data?.frequency?.certainDate)
        ).toLocaleString()}`;
      case "date_field":
        return `based on the date field ${data?.frequency?.dateField}`;
      case "event_triggered":
        return `when the event ${data?.frequency?.event} occurs`;
      default:
        return "an unknown trigger";
    }
  };

  const getFilteringDescription = () => {
    const groups =
      queryResult?.data && queryResult?.data?.total > 0 ? (
        <>
          <strong>Groups</strong>:
          {queryResult?.data?.data?.map((item) => (
            <span key={item.id}> {item.name}</span>
          ))}
        </>
      ) : null;

    const operatorMapping = {
      "=": "equals",
      "!=": "does not equal",
      "<": "is less than",
      ">": "is greater than",
      "<=": "is less than or equal to",
      ">=": "is greater than or equal to",
      contains: "contains",
      "does not contain": "does not contain",
      in: "is in",
      "not in": "is not in",
      within: "is within",
      beginsWith: "begins with",
      endsWith: "ends with",
      inXDays: "in the next",
      // Add more operators as needed
    };

    const processRule = (rule) => {
      const field = rule.field
        .replace(/contacts\.|custom_fields->>/g, "")
        .replace(/_/g, " ")
        .trim();
      const operator = operatorMapping[rule.operator] || rule.operator;
      const value = rule.value;

      return (
        <>
          <strong>{field}</strong> {operator} <em>{value}</em>
        </>
      );
    };

    const processQuery = (query) => {
      if (query.rules) {
        const rulesDescriptions = query.rules.map((rule, index) => {
          if (rule.rules) {
            return (
              <React.Fragment key={index}>
                ({processQuery(rule)})
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={index}>{processRule(rule)}</React.Fragment>
            );
          }
        });

        return rulesDescriptions.reduce((prev, curr, index) => {
          if (index === 0) {
            return [curr];
          } else {
            return [...prev, ` ${query.combinator.toUpperCase()} `, curr];
          }
        }, []);
      } else {
        return null;
      }
    };

    const segment = data?.criterias?.segment?.query ? (
      <>
        <strong>Segment</strong>:{" "}
        {processQuery(data?.criterias?.segment?.query)}
      </>
    ) : null;

    const descriptions = [groups, segment].filter(Boolean);
    return descriptions.reduce((prev: any, curr, index) => {
      if (index === 0) {
        return [curr];
      } else {
        return [...prev, " and ", curr];
      }
    }, []);
  };

  return (
    <Typography.Text
      style={{
        textAlign: "center",
        maxWidth: "80%",
        margin: "0 auto",
        display: "block",
      }}
    >
      This automation is based on{" "}
      <Typography.Text strong>{data.based_on}</Typography.Text> and will run{" "}
      <Typography.Text strong>{getWhenToStartDescription()}</Typography.Text>.{" "}
      {getFilteringDescription() && (
        <>
          It is filtered by{" "}
          <Typography.Text>{getFilteringDescription()}</Typography.Text>.
        </>
      )}
    </Typography.Text>
  );
}
