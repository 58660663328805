import { useSelect } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Form, FormItemProps, Select, SelectProps } from "antd";
import Typography from "antd/lib/typography";
import { useUser } from "../../contexts/ContextProvider";
import { Category } from "../../interfaces/shop.interface";
import { updateCategoryPathnames } from "../../utility/shop";

type Props = {
  ignoreId?: string;
  name?: (string | number)[] | string;
  onSelect?: (category: any) => void;
  multiple?: boolean;
  itemProps?: FormItemProps;
  selectProps?: SelectProps;
};
export default function CategoryAutoselect({
  ignoreId,
  name,
  onSelect,
  multiple,
  itemProps,
  selectProps: customSelectProps,
}: Props) {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { selectProps, query } = useSelect<Category>({
    resource: "product_categories",
    meta: { select: "*" },
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
    optionLabel: "name",
    optionValue: "id",
  });
  return (
    <Form.Item
      name={name ?? "parent"}
      label={translate(
        `product_categories.belongs_to_${multiple ? "categories" : "category"}`
      )}
      {...itemProps}
    >
      <Select
        {...selectProps}
        mode={multiple ? "multiple" : undefined}
        onChange={(value) => {
          onSelect?.(value);
        }}
        options={
          updateCategoryPathnames(query.data?.data ?? [])
            ?.filter((item) => String(item.id) != String(ignoreId))
            .map((category) => ({
              value: category.id,
              label: (
                <>
                  <Typography.Text>{category.pathname}</Typography.Text>
                </>
              ),
            })) ?? []
        }
        allowClear
        {...customSelectProps}
      />
    </Form.Item>
  );
}
