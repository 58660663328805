import * as React from "react"
const LexwareIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1114.667}
    height={488}
    fontSize={48}
    viewBox="0 0 836 366"
    {...props}
  >
    <path d="M227.7 101.1c-20 4.7-34.5 26.8-31 47.3 4.6 26.8 30.7 42.1 55.6 32.6 7-2.6 16.6-11.2 20.8-18.4 1.6-2.8 2.9-5.4 2.9-5.9 0-.4-5.4-.7-11.9-.7-11.9 0-12 0-14.9 2.9-7.5 7.5-21.8 5.5-27.1-3.9-1.1-2.1-2.1-4-2.1-4.4 0-.3 13-.6 29-.6h28.9l.6-3.4c1.1-5.6-1.2-16.5-5-24.3-8.2-16.6-27.4-25.5-45.8-21.2zm17 21.1c3.4 1 9.3 6.9 9.3 9.3 0 1.3-2.7 1.5-17.1 1.5-9.3 0-16.9-.3-16.7-.8 2.4-4.9 3.7-6.6 6.6-8.4 6-3.7 10-4 17.9-1.6zM513.8 101.5c-7 1.9-12.9 5.7-18.6 12-6.7 7.3-9.4 14.3-10 26.1-1.2 24.5 13.1 42.4 35.2 44.1 9.4.7 16.5-1 22.2-5.3l4.4-3.4v6h24v-78h-24v3c0 1.6-.3 3-.6 3-.4 0-2.2-1.3-4-3-6.3-5.5-18.3-7.4-28.6-4.5zM537 125c2.2 1.1 5.2 3.9 6.7 6.2 2.4 3.5 2.8 5.2 2.8 10.8 0 5.6-.4 7.3-2.8 10.9-10.6 15.9-34.6 8.5-34.7-10.7 0-7.6 4.3-15 10.5-17.8 4.6-2 12.9-1.7 17.5.6zM618 101.3c-2.6.7-6.3 2.3-8.3 3.6-3.7 2.3-3.7 2.3-3.7.2 0-2-.5-2.1-12-2.1h-12v78h24v-23.8c0-21.7.2-24 2-26.9 2.6-4.3 7.1-6.5 12.7-6.2l4.5.3 4.4-11.3c2.4-6.3 4.3-11.6 4-11.8-1.1-1.1-11.6-1.1-15.6 0zM662 101.4c-20.3 5.7-34 27.3-30.2 47.7 6.1 33 45.3 46.2 68.6 23.3 4.2-4.2 10.6-13.6 10.6-15.7 0-.4-5.2-.7-11.6-.7-11.5 0-11.7 0-15 3-8.5 7.4-20.6 5.6-27.1-4-1.3-1.8-2.3-3.7-2.3-4.1 0-.5 13-.9 29-.9h28.9l.7-3.6c.9-5-1.2-16.6-4.2-23-8.2-17.4-29-27.1-47.4-22zm19.6 21.4c2.8 1.5 7.4 7 7.4 8.8 0 1.2-3 1.4-17 1.4h-17.1l1.5-2.9c3.6-6.9 11.4-10.5 19.6-9.1 1.9.4 4.5 1.2 5.6 1.8zM130 142v39h63v-22h-36v-56h-27v39z" />
    <path d="M272 103.5c0 .3 7 9.1 15.5 19.6l15.4 19-14.7 18.2c-8.2 10-15.1 18.8-15.6 19.4-.6 1 2.5 1.3 14.1 1.2h14.8l15-18.7c8.3-10.2 15.1-19.1 15.3-19.7.1-.6-6.6-9.6-15.1-20.1l-15.3-18.9-14.7-.3c-8.1-.1-14.7 0-14.7.3zM329.2 109.6c-2.9 3.6-5.5 7-5.8 7.7-.2.7 2.8 5.2 6.7 10.1 5.8 7.2 7.4 8.6 8.4 7.5.7-.8 6.1-7.5 12-14.9 6-7.4 11.5-14.3 12.3-15.3 1.4-1.6.7-1.7-13.4-1.7h-14.9l-5.3 6.6zM366.2 104.4c.2.7 5.5 18.3 11.8 39l11.5 37.6h22.4l.9-3.3c.5-1.7 3.3-12.6 6.2-24 2.9-11.4 5.6-20.4 6-20 .4.4 3.3 11.3 6.6 24l5.9 23.3h22.4l2.6-8.3c1.4-4.5 6.7-21.9 11.8-38.7l9.4-30.5-12-.3c-6.6-.1-12.2-.1-12.4.1-.2.3-2.6 9.9-5.4 21.3-5.6 23.7-5.9 24.7-6.5 24.1-.3-.2-2.7-8.8-5.4-19.1s-5.4-20.4-6-22.6l-1.1-4h-20.4l-6 23c-3.3 12.6-6.2 23-6.5 23-.3 0-2.9-10.4-5.9-23l-5.5-23h-12.4c-10 0-12.3.3-12 1.4zM330.1 156.9c-4.6 5.8-7 9.5-6.5 10.3.4.7 3 4.1 5.8 7.5l5 6.3H364.1l-12.7-15.8c-7-8.6-13-16.1-13.4-16.5-.4-.5-4 3.2-7.9 8.2zM493.3 201.9c-14.1 4.8-22.1 17.6-21.1 33.5.9 12.4 7.5 22.2 18.6 27.4 4.9 2.3 7.1 2.7 14.7 2.7 8 0 9.7-.3 14.8-2.9 15-7.7 21.9-25.2 16.3-41.5-2.6-7.6-11.1-16.1-18.9-19-7.2-2.6-17.2-2.7-24.4-.2zm20.5 8.7c8.9 3.7 14.2 12 14.2 22.4 0 8-2.2 13.2-7.8 18.2-12 10.8-31.2 5.7-36.8-9.9-4.2-11.8 1.7-26 12.6-30.7 4.6-2 13.1-2 17.8 0zM556.2 201.7c-3.9 1.9-6 5.5-6.9 11.8-.5 4.1-.8 4.5-3.4 4.5-2.6 0-2.9.3-2.9 3.5s.2 3.5 3 3.5h3v40h9v-40h15v40h9v-40h13v40h9v-47h-22v-3.9c0-4.2 2.6-7.1 6.2-7.1 1.4 0 1.8-.8 1.8-3.5 0-3.3-.2-3.5-3.5-3.5-7.6 0-12.7 4.7-13.5 12.3l-.5 5.2-7.4.3-7.4.3.6-3.7c.8-4.5 3.2-7.4 6.4-7.4 2 0 2.3-.5 2.3-3.5 0-3.4-.1-3.5-3.7-3.5-2.1 0-5.3.8-7.1 1.7z" />
    <path d="M595 205.5v4.5h9v-9h-9v4.5zM629.7 217.1c-19.4 4.6-26 28.2-11.9 41.9 5.3 5.1 10.3 7 18.1 7 8.5 0 15.3-3.7 20.4-11.2 3.8-5.7 3.5-6.8-1.3-6.8-3.5 0-4.6.6-8.3 4.4-13.8 14.4-35.2-3-24.7-20.2 5.8-9.4 20.8-9.5 26.4-.1 1.5 2.5 2.3 2.9 6.2 2.9 5.4 0 5.6-1 1.2-7.6-5.3-7.9-17-12.5-26.1-10.3zM684.9 216.9c-12.1 2.5-20.9 14.3-19.6 26.6 1.8 17.8 19.7 27.8 35.6 19.9 4.7-2.4 11.1-9 11.1-11.6 0-.4-1.9-.8-4.2-.8-3.4 0-4.9.6-7.4 3.1-8.4 8.1-23.2 4-25.9-7.4l-.7-2.7H714v-4.5c0-14.2-14.6-25.5-29.1-22.6zm11.8 9.1c4 1.8 8.3 6.8 8.3 9.7 0 1-3.8 1.3-15.4 1.3h-15.5l1-2.9c1.3-3.6 5.8-8 9.5-9.1 4.6-1.3 7.6-1.1 12.1 1z" />
  </svg>
)
export default LexwareIcon
