// src/pages/ProductIssueShow.tsx

import {
  BaseKey,
  useDelete,
  useNavigation,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Button, Input, Modal, Popconfirm, Spin, Tag } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { SingleElementForm } from "../../../../components";
import { SingleElementFormV2 } from "../../../../components/single-element-form/single-element-form-v2";

const ProductIssueShow: React.FC = () => {
  const { issueId: id, id: prodId } = useParams();
  const { show } = useNavigation();
  const translate = useTranslate();
  const [activeForm, setActiveForm] = useState<"name">();
  const { query } = useShow({
    resource: "product_issues",
    id,
  });

  const { data, isLoading } = query;

  const { mutate: deleteMutate } = useDelete();

  const handleDelete = () => {
    deleteMutate(
      {
        resource: "product_issues",
        id: id ?? 0,
      },
      {
        onSuccess: () => {
          show("product_items", prodId as BaseKey);
        },
      }
    );
  };

  if (isLoading) {
    return (
      <Spin tip="Loading...">
        <div style={{ minHeight: "200px" }} />
      </Spin>
    );
  }

  return (
    <Modal
      open
      onClose={() => show("product_items", prodId as BaseKey)}
      onCancel={() => show("product_items", prodId as BaseKey)}
      title="Issue Details"
      width={700}
      footer={
        <Popconfirm
          title="Are you sure you want to delete this issue?"
          onConfirm={handleDelete}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      }
    >
      <SingleElementForm
        state={
          activeForm && activeForm === "name"
            ? "form"
            : data?.data?.name
            ? "view"
            : "empty"
        }
        itemProps={{
          name: "title",
          label: translate("product_items.product_issues.name"),
          initialValue: data?.data?.name,
        }}
        useFormProps={{
          resource: "product_items",
          id: id,
        }}
        view={<>{data?.data?.name}</>}
        onClick={() => setActiveForm("name")}
        onUpdate={() => setActiveForm(undefined)}
        onCancel={() => setActiveForm(undefined)}
      >
        <Input />
      </SingleElementForm>
      <SingleElementFormV2
        itemProps={{
          name: "title",
          label: translate("product_items.product_issues.status"),
          initialValue: data?.data?.status,
        }}
        useFormProps={{
          resource: "product_items",
          id: id,
        }}
        disableHoverBg
        view={<Tag>{data?.data?.status}</Tag>}
      />
    </Modal>
  );
};

export default ProductIssueShow;
