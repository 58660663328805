import { useList, useTranslate } from "@refinedev/core";

import { Button, Form, Select } from "antd";

import debounce from "lodash/debounce";
import React, { useEffect, useRef, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { getContactFormatedName } from "../../utility/contactName";
import CreateContactModal from "./CreateContactModal";
interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}
type Props = {
  initialValue?: any;
  onSelect?: (value: string) => void;
  filterIds?: string[];
  label?: string;
  name?: string;
  initialOption?: { value: string | number; label: React.ReactNode };
  required?: boolean;
};
export default function ContactAutoSelect({
  initialValue,
  onSelect,
  filterIds,
  label,
  name,
  initialOption,
  required = true,
}: Props) {
  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptionGroup[]>([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const { currentTeam } = useUser();
  const renderItem = (title: string | undefined, id: string) => ({
    value: id,
    label: title,
  });
  const { refetch: refetchContacts } = useList<any>({
    resource: "contacts",
    filters: [
      {
        operator: "or",
        value: [
          {
            field: "firstname",
            operator: "contains",
            value: `%${value}%`,
          },
          {
            field: "lastname",
            operator: "contains",
            value: `%${value}%`,
          },
          {
            field: "company_name",
            operator: "contains",
            value: `%${value}%`,
          },
        ],
      },
      {
        field: "account",
        operator: "eq",
        value: currentTeam?.account_id,
      },
    ],
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const orderOptionGroup = data.data.map((item) =>
          renderItem(
            getContactFormatedName(
              item?.company_name,
              item?.firstname,
              item?.lastname,
              item?.email
            ),
            `${item.id}`
          )
        );
        if (filterIds && orderOptionGroup.length > 0 && filterIds.length > 0) {
          const stringFilterIds = filterIds.map(String);
          const filteredOptions = orderOptionGroup.filter((obj) => {
            return !stringFilterIds.includes(obj.value);
          });
          setOptions(filteredOptions);
        } else {
          setOptions(orderOptionGroup);
        }
      },
    },
  });
  const inputRef = useRef<any>(null);
  useEffect(() => {
    refetchContacts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, currentTeam?.account_id]);

  const translate = useTranslate();
  return (
    <>
      {currentTeam?.account_id && (
        <Form.Item
          name={name ? name : !onSelect ? "contact" : undefined}
          rules={[{ required: required }]}
          label={label ? label : translate("pipeline_items.contact")}
          style={
            {
              // marginBottom: 0,
            }
          }
          // initialValue={initialValue ?? ""}
        >
          <Select
            style={{
              width: "100%",
              minWidth: "150px",
              marginRight: "1rem",
            }}
            showSearch
            allowClear
            onClear={() => onSelect && onSelect("")}
            value={
              typeof initialValue != "object"
                ? String(initialValue ?? "")
                : undefined
            }
            onClick={() => setOpenDialog(!openDialog)}
            options={[
              ...options,
              ...(initialOption &&
              !options.some((item) => item.value == initialOption?.value)
                ? [initialOption]
                : []),
            ]}
            filterOption={false}
            onSearch={debounce((value: string) => setValue(value), 300)}
            onSelect={(value: string) => {
              onSelect ? onSelect(String(value)) : "";
            }}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <div>
                  <Button
                    block
                    type="default"
                    size="small"
                    ref={inputRef}
                    onClick={() => setOpenCreateModal(true)}
                  >
                    {translate("contacts.create_new_contact")}
                  </Button>
                </div>
              </div>
            )}
          />
        </Form.Item>
      )}
      {openCreateModal && (
        <CreateContactModal
          open={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
          onSuccess={async (contact) => {
            setOptions((prev) => [
              ...prev,
              {
                value: contact.id + "",
                label: getContactFormatedName(
                  contact?.company_name,
                  contact?.firstname,
                  contact?.lastname,
                  contact?.email
                ),
              },
            ]);
            setOpenDialog(() => (onSelect ? false : true));
            setTimeout(() => {
              if (contact.id) onSelect?.(contact.id + "");
              inputRef.current?.focus();
            }, 500);
            setOpenCreateModal(false);
          }}
        />
      )}
    </>
  );
}
