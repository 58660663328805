import { useNavigation } from "@refinedev/core";
import { Breadcrumb } from "antd";

const CustomBreadcrumb = ({ items }) => {
  const { push } = useNavigation();

  const handleClick = (url, e) => {
    e.preventDefault();
    push(url);
  };

  // Map the items to the format expected by Breadcrumb's new `items` prop
  const breadcrumbItems = items.map((item) => ({
    key: item.url || item.title,
    title: item.url ? (
      <span
        onClick={(e) => handleClick(item.url, e)}
        className="clickableRow"
        style={{ fontWeight: "normal" }}
      >
        {item.icon && item.icon} {item.title}
      </span>
    ) : (
      <span style={{ fontWeight: "normal" }}>
        {item.icon && item.icon} {item.title}
      </span>
    ),
  }));

  return <Breadcrumb items={breadcrumbItems} />;
};

export default CustomBreadcrumb;
