import { useEffect } from "react";
import useModules from "../../hooks/useModules";
import { usePipelineContext } from "../../contexts/PipelineProvider";

export const Module = () => {
  const { fetchModuleData, getSlugFromUrl } = useModules();
  const { setCanAccess } = usePipelineContext();

  const teamSlug = getSlugFromUrl();

  function getDynamicLocalStorageItem() {
    const regex = /^sb-.*-auth-token$/;
    for (let i = 0; i < localStorage.length; i++) {
      const key: any = localStorage.key(i);
      if (regex.test(key)) {
        return localStorage.getItem(key);
      }
    }
    return null;
  }
  useEffect(() => {
    if (getDynamicLocalStorageItem()) fetchModuleData(teamSlug);
    else {
      setCanAccess(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchModuleData, teamSlug]);

  return null;
};
