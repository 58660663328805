import { useSelect } from "@refinedev/antd";
import { Select } from "antd";
import React from "react";
import { formatQuery, RuleGroupType } from "react-querybuilder";
import { useTranslate } from "@refinedev/core";
import { useUser } from "../../../contexts/ContextProvider";

const defaulQuery: RuleGroupType = { combinator: "and", rules: [] };

type Props = {
  value?: any;
  onChange?: (value: any) => void;
  onClear?: (byClearButton?: boolean) => Promise<void>;
};

export default function SegmentsAutoComplete({
  value = [],
  onChange,
  onClear,
}: Props) {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { query: results } = useSelect({
    resource: "segments",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
  });
  return (
    <Select
      style={{
        width: "100%",
        minWidth: "200px",
        marginRight: "1rem",
      }}
      showSearch
      allowClear
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      placeholder={translate("contacts.filter_segment_placeholder")}
      options={[
        { value: -1, label: "Custom" },
        ...(results?.data?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        })) ?? []),
      ]}
      value={value ?? []}
      onClear={() => onClear?.(true)}
      onChange={async (value) => {
        await onClear?.();
        if (value == -1) {
          onChange?.({
            id: -1,
            name: "Custom Field",
            query: formatQuery(defaulQuery, "jsonlogic"),
          });
        } else {
          const newVal = results?.data?.data.find((i: any) => i.id === value);
          onChange?.(newVal);
        }
      }}
    />
  );
}
