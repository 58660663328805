const OutlookIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    fontSize={40}
    height={800}
    viewBox="0 0 32 32"
    {...props}
  >
    <title>{"file_type_outlook"}</title>
    <path
      d="M19.484 7.937v5.477l1.916 1.205a.489.489 0 0 0 .21 0l8.238-5.554a1.174 1.174 0 0 0-.959-1.128Z"
      style={{
        fill: "#0072c6",
      }}
    />
    <path
      d="m19.484 15.457 1.747 1.2a.522.522 0 0 0 .543 0c-.3.181 8.073-5.378 8.073-5.378v10.066a1.408 1.408 0 0 1-1.49 1.555h-8.874v-7.443ZM10.44 12.932a1.609 1.609 0 0 0-1.42.838 4.131 4.131 0 0 0-.526 2.218A4.05 4.05 0 0 0 9.02 18.2a1.6 1.6 0 0 0 2.771.022 4.014 4.014 0 0 0 .515-2.2 4.369 4.369 0 0 0-.5-2.281 1.536 1.536 0 0 0-1.366-.809Z"
      style={{
        fill: "#0072c6",
      }}
    />
    <path
      d="M2.153 5.155v21.427L18.453 30V2Zm10.908 14.336a3.231 3.231 0 0 1-2.7 1.361 3.19 3.19 0 0 1-2.64-1.318A5.459 5.459 0 0 1 6.706 16.1a5.868 5.868 0 0 1 1.036-3.616 3.267 3.267 0 0 1 2.744-1.384 3.116 3.116 0 0 1 2.61 1.321 5.639 5.639 0 0 1 1 3.484 5.763 5.763 0 0 1-1.035 3.586Z"
      style={{
        fill: "#0072c6",
      }}
    />
  </svg>
);
export default OutlookIcon;
