import {
  useDelete,
  useNavigation,
  useShow,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import React, { PropsWithChildren, useEffect, useState } from "react";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "@refinedev/antd";
import {
  Button,
  Card,
  Collapse,
  DatePicker,
  Drawer,
  Flex,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import { CollapseProps } from "antd/lib";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { SingleElementForm, Text } from "../../../components";
import AddressAutoselect from "../../../components/common/AddressAutoselect";
import ContactAutoSelect from "../../../components/contacts/ContactAutoSelect";
import SinglePhase from "../../../components/shop/single-phase";
import { SingleElementFormV2 } from "../../../components/single-element-form/single-element-form-v2";
import { useUser } from "../../../contexts/ContextProvider";
import { BillingType } from "../../../interfaces/phase.interface";
import { supabaseClient } from "../../../utility";
import { getContactFormatedName } from "../../../utility/contactName";

const SubscriptionsShow: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeForm, setActiveForm] = useState<
    | "title"
    | "description"
    | "earliest_cancellation_date"
    | "invoice_contact_address"
    | "delivery_contact"
  >();
  const [deliveryContact, setDeliveryContact] = useState<string | undefined>(
    undefined
  );
  const [deliveryContactAddress, setDeliveryContactAddress] = useState<
    string | undefined
  >(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(
    null
  );
  const params = useParams();
  const { currentTeam } = useUser();
  const { list } = useNavigation();
  const { mutate: deleteMutation } = useDelete();
  const { mutate: updateOne } = useUpdate();
  const { query } = useShow({
    resource: "subscriptions",
    id: params.subscriptionId,
    meta: {
      select:
        "*, invoice_contact(*, phone_numbers(*)), invoice_contact_address(*), delivery_contact(*), delivery_contact_address(*)",
    },
  });

  const { formProps, form } = useForm({
    action: "edit",
    redirect: false,
    autoSave: {
      enabled: true,
      invalidateOnUnmount: true,
    },
    onMutationSuccess: () => {
      query.refetch();
    },
    invalidates: ["all"],
    mutationMode: "pessimistic",
  });
  const translate = useTranslate();

  const closeModal = () => {
    list("subscriptions", "replace");
  };
  const handleContactSelect = (value: string | undefined) => {
    // Update the selected contact
    setDeliveryContact(value);
    setDeliveryContactAddress(undefined);
    // Reset the Address field when a new contact is selected
    if (value === undefined) {
      form.resetFields(["delivery_contact_address"]);
      setDeliveryContactAddress(undefined);
    } else {
      // Reset the delivery contact address field in the form
      form.resetFields(["delivery_contact_address"]);
      setDeliveryContactAddress(undefined);
    }
  };

  const handleAddressSelect = (value: string | undefined) => {
    setDeliveryContactAddress(value);
  };

  const { data, isLoading, isError } = query;
  useEffect(() => {
    setDeliveryContact(data?.data?.delivery_contact?.id);
    setDeliveryContactAddress(data?.data?.delivery_contact_address?.id);
  }, [
    data?.data?.delivery_contact?.id,
    data?.data?.delivery_contact_address?.id,
  ]);
  if (isError) {
    closeModal();
    return null;
  }
  if (isLoading && !currentTeam?.account_id) {
    return (
      <Drawer
        open
        styles={{
          body: {
            background: "#f5f5f5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        className="entityDrawer"
      >
        <Spin />
      </Drawer>
    );
  }

  const {
    id,
    name,
    phases,
    created_at,
    invoice_contact,
    earliest_cancellation_date,
    issues_left,
    invoice_contact_address,
    delivery_contact,
    delivery_contact_address,
  } = data?.data ?? {};
  const items: CollapseProps["items"] = phases?.map(
    (item: any, index: number) => {
      return {
        key: (index + 1).toString(),
        extra: (
          <Popconfirm
            title={translate(
              "subscription_products.buttons.delete.title_single"
            )}
            description={translate(
              "subscription_products.buttons.delete.description_single"
            )}
            onConfirm={() => {
              handlePhaseDelete(index);
            }}
            okText={translate("buttons.yes", "Yes")}
            cancelText={translate("buttons.no", "No")}
          >
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              style={{ fontSize: "0.80rem", padding: 7 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {translate("subscription_products.show.delete_phase")}
            </Button>
          </Popconfirm>
        ),
        label: (
          <Typography.Link>
            {dayjs(item.start).format(translate("global.date_format"))}
            {" - "}
            {item.end == BillingType.Forever
              ? (translate(
                  "subscription_products.fields.billing_type." +
                    BillingType.Forever
                ) as string)
              : dayjs(item.end).format(translate("global.date_format"))}
          </Typography.Link>
        ),
        children: (
          <SinglePhase
            handleChange={(phase) => handleChangesOnPhases(phase, index)}
            handlePhaseDelete={() => handlePhaseDelete(index)}
            data={item}
            isSubscription
          />
        ),
      };
    }
  );

  function handlePhaseDelete(index: number) {
    const changedPhase = phases.filter(
      (_item, indexFilter) => index != indexFilter
    );
    form.setFieldValue("phases", changedPhase);
    form.submit();
  }

  function handleChangesOnPhases(phase: any, index: number) {
    const changedPhase = phases.map((item, indexPhase) => {
      if (indexPhase == index) {
        item = phase;
      }
      return item;
    });

    form.setFieldValue("phases", changedPhase);
  }

  const handleAddNewPhase = () => {
    const lastPhase = phases[phases.length - 1];
    let newPhase;
    if (lastPhase)
      newPhase = {
        ...lastPhase,
        key: lastPhase.key + 1,
        start: lastPhase.start,
        end: lastPhase.end,
        billing_interval: { ...lastPhase.billing_interval },
        products: lastPhase.products.map((item) => ({ ...item })),
      };
    else
      newPhase = {
        key: 1,
        billing_interval: {
          interval: "month",
          interval_count: 3,
        },
        start: dayjs().format(translate("global.date_format")),
        end: dayjs().format(translate("global.date_format")),
        products: [],
      };
    const updatedPhases = [...phases, newPhase];

    form.setFieldValue("phases", updatedPhases);
    form.submit();
  };

  // Process phases to get total issues per product and product names
  const totalIssuesPerProduct: {
    [key: number]: { issues: number; name: string };
  } = {};

  phases?.forEach((phase: any) => {
    phase?.products?.forEach((item: any) => {
      if (item.type === "issue") {
        const productId = item.product;
        const issues = item.issues || 0;
        const productName = item.name || "Unknown product";

        if (totalIssuesPerProduct[productId]) {
          totalIssuesPerProduct[productId].issues += issues;
        } else {
          totalIssuesPerProduct[productId] = {
            issues: issues,
            name: productName,
          };
        }
      }
    });
  });

  // Get issues left from issues_left field
  let issuesLeftData: any = [];
  if (issues_left) {
    issuesLeftData = Object.entries(issues_left).map(
      ([productId, issuesLeft]) => ({
        productId: Number(productId),
        issuesLeft: Number(issuesLeft),
      })
    );
  }

  // Combine total issues and issues left to calculate issues used
  const issuesLeftWithProductNames = Object.entries(totalIssuesPerProduct).map(
    ([productId, value]) => {
      const issuesLeftEntry = issuesLeftData.find(
        (item) => item.productId === Number(productId)
      );
      const issuesLeft = issuesLeftEntry
        ? issuesLeftEntry.issuesLeft
        : value.issues;
      const issuesUsed = value.issues - issuesLeft;
      return {
        productId: Number(productId),
        productName: value.name,
        totalIssues: value.issues,
        issuesLeft: issuesLeft,
        issuesUsed: issuesUsed,
      };
    }
  );

  const columns = [
    {
      title: translate("subscriptions.issues_left.product_name"),
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: translate("subscriptions.issues_left.issues_left"),
      dataIndex: "issuesLeft",
      key: "issuesLeft",
    },
    {
      key: "action",
      render: (_text: any, record: any) => (
        <Button
          onClick={() => handleSendIssue(record.productId)}
          type="primary"
        >
          {translate("subscriptions.issues_left.send_issue_manually")}
        </Button>
      ),
    },
  ];

  const handleSendIssue = (productId: number) => {
    setSelectedProductId(productId);
    setIsModalOpen(true);
  };

  const handleModalOk = async () => {
    if (params?.id)
      await supabaseClient.functions.invoke(
        "shop-core/subscription/issue/send-manually",
        {
          body: {
            subscriptionId: parseInt(params?.id),
            productId: selectedProductId,
          },
          method: "POST",
        }
      );
    setIsModalOpen(false);
  };
  return (
    <>
      <Drawer
        open
        onClose={() => closeModal()}
        title={translate("subscriptions.show.title")}
        className="entityDrawer"
        footer={
          <Flex justify="space-between" align="center">
            <Text className="ant-text tertiary">
              {translate("subscription_products.fields.created_at")}{" "}
              {dayjs(created_at).format(translate("page.settings.date_format"))}{" "}
              {translate("page.settings.clock")}
            </Text>
            <div>
              <Popconfirm
                title={translate("subscriptions.buttons.delete.title")}
                description={translate(
                  "subscriptions.buttons.delete.description"
                )}
                onConfirm={() => {
                  if (id) {
                    deleteMutation(
                      {
                        id,
                        resource: "subscriptions",
                      },
                      {
                        onSuccess: () => closeModal(),
                      }
                    );
                  }
                }}
                okText={translate("buttons.yes", "Yes")}
                cancelText={translate("buttons.no", "No")}
              >
                <Button type="link" danger icon={<DeleteOutlined />}>
                  {translate("subscriptions.buttons.delete.title")}
                </Button>
              </Popconfirm>
              <Button onClick={() => form.submit()} type="primary">
                Save
              </Button>
            </div>
          </Flex>
        }
      >
        <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
          <Card
            title={
              <>
                <Text>{translate("subscription_products.show.basic")}</Text>
              </>
            }
          >
            <SingleElementForm
              state={
                activeForm && activeForm === "title"
                  ? "form"
                  : name
                  ? "view"
                  : "empty"
              }
              itemProps={{
                name: "name",
                label: translate("subscription_products.fields.title"),
                initialValue: name,
              }}
              useFormProps={{
                resource: "subscriptions",
                id: id,
              }}
              view={<Text>{name}</Text>}
              onClick={() => setActiveForm("title")}
              onUpdate={() => setActiveForm(undefined)}
              onCancel={() => setActiveForm(undefined)}
            >
              <Input />
            </SingleElementForm>
            <SingleElementForm
              state={
                activeForm && activeForm === "earliest_cancellation_date"
                  ? "form"
                  : earliest_cancellation_date
                  ? "view"
                  : "empty"
              }
              itemProps={{
                name: "earliest_cancellation_date",
                label: translate(
                  "subscription_products.fields.earliest_cancellation_date"
                ),
                initialValue: dayjs(earliest_cancellation_date),
                getValueProps: (value) => ({
                  value: value && dayjs(value.earliest_cancellation_date),
                }),
              }}
              useFormProps={{
                resource: "subscriptions",
                id: id,
              }}
              view={<Text>{earliest_cancellation_date}</Text>}
              onClick={() => setActiveForm("earliest_cancellation_date")}
              onUpdate={() => setActiveForm(undefined)}
              onCancel={() => setActiveForm(undefined)}
            >
              <DatePicker minDate={dayjs()} />
            </SingleElementForm>
            <SingleElementForm
              state={
                activeForm && activeForm === "invoice_contact_address"
                  ? "form"
                  : invoice_contact_address
                  ? "view"
                  : "empty"
              }
              itemProps={{
                name: "invoice_contact_address",
                label: translate(
                  "subscriptions.fields.invoice_contact_address"
                ),
              }}
              useFormProps={{
                resource: "subscriptions",
                id: id,
              }}
              view={
                <Space size={"middle"} align="start">
                  <Text>
                    {/* Display fields based on address type */}
                    {invoice_contact_address?.type === "PO_Box_Address" ? (
                      <>
                        {/* P.O. Box Address */}
                        <Row>{invoice_contact_address?.street}</Row>
                      </>
                    ) : invoice_contact_address?.type === "Packing_Station" ? (
                      <>
                        {/* Postnummer (c/o) */}
                        {invoice_contact_address?.address_supplement_1 && (
                          <Row>
                            {translate("contacts.post_number")}:{" "}
                            {invoice_contact_address?.address_supplement_1}
                          </Row>
                        )}
                        {/* Packstation */}
                        <Row>
                          {translate("contacts.packstation")}:{" "}
                          {invoice_contact_address?.street}
                        </Row>
                      </>
                    ) : (
                      <>
                        {/* Home Address */}
                        {invoice_contact_address?.address_supplement_1 && (
                          <Row>
                            {invoice_contact_address?.address_supplement_1}
                          </Row>
                        )}
                        <Row>{invoice_contact_address?.street}</Row>
                      </>
                    )}
                    <Row>
                      {invoice_contact_address?.zip}{" "}
                      {invoice_contact_address?.city}
                    </Row>
                    <Row>{invoice_contact_address?.country}</Row>
                  </Text>
                  {invoice_contact_address?.label && (
                    <Tag>{invoice_contact_address?.label}</Tag>
                  )}
                </Space>
              }
              onClick={() => setActiveForm("invoice_contact_address")}
              onUpdate={() => setActiveForm(undefined)}
              onCancel={() => setActiveForm(undefined)}
            >
              <AddressAutoselect
                contactId={invoice_contact?.id}
                itemProps={{ initialValue: invoice_contact_address?.id }}
                label=""
              />
            </SingleElementForm>
            <SingleElementForm
              state={
                activeForm && activeForm === "delivery_contact"
                  ? "form"
                  : delivery_contact
                  ? "view"
                  : "empty"
              }
              itemProps={{
                label: translate("subscriptions.fields.delivery_contact"),
              }}
              useFormProps={{
                resource: "subscriptions",
                id: id,
              }}
              view={
                <Space size={"middle"} align="start">
                  <Text>
                    {getContactFormatedName(
                      delivery_contact?.company_name,
                      delivery_contact?.firstname,
                      delivery_contact?.lastname,
                      delivery_contact?.email
                    )}
                    {/* Display fields based on address type */}
                    {delivery_contact_address?.type === "PO_Box_Address" ? (
                      <>
                        {/* P.O. Box Address */}
                        <Row>{delivery_contact_address?.street}</Row>
                      </>
                    ) : delivery_contact_address?.type === "Packing_Station" ? (
                      <>
                        {/* Postnummer (c/o) */}
                        {delivery_contact_address?.address_supplement_1 && (
                          <Row>
                            {translate("contacts.post_number")}:{" "}
                            {delivery_contact_address?.address_supplement_1}
                          </Row>
                        )}
                        {/* Packstation */}
                        <Row>
                          {translate("contacts.packstation")}:{" "}
                          {delivery_contact_address?.street}
                        </Row>
                      </>
                    ) : (
                      <>
                        {/* Home Address */}
                        {delivery_contact_address?.address_supplement_1 && (
                          <Row>
                            {delivery_contact_address?.address_supplement_1}
                          </Row>
                        )}
                        <Row>{delivery_contact_address?.street}</Row>
                      </>
                    )}
                    <Row>
                      {delivery_contact_address?.zip}{" "}
                      {delivery_contact_address?.city}
                    </Row>
                    <Row>{delivery_contact_address?.country}</Row>
                  </Text>
                  {delivery_contact_address?.label && (
                    <Tag>{delivery_contact_address?.label}</Tag>
                  )}
                </Space>
              }
              formProps={{
                onFinish: (values) => {
                  const val = {
                    ...values,
                    delivery_contact: deliveryContact || null,
                    delivery_contact_address: deliveryContactAddress || null,
                  };
                  updateOne({
                    resource: "subscriptions",
                    id: id,
                    values: val,
                  });
                },
              }}
              onClick={() => setActiveForm("delivery_contact")}
              onUpdate={() => setActiveForm(undefined)}
              onCancel={() => setActiveForm(undefined)}
            >
              <ContactAutoSelect
                onSelect={handleContactSelect}
                name="delivery_contact"
                initialOption={{
                  value: parseInt(delivery_contact?.id),
                  label: getContactFormatedName(
                    delivery_contact?.company_name,
                    delivery_contact?.firstname,
                    delivery_contact?.lastname
                  ),
                }}
                initialValue={deliveryContact}
                required={false}
              />
              {deliveryContact && (
                <AddressAutoselect
                  name="delivery_contact_address"
                  contactId={deliveryContact}
                  onSelect={handleAddressSelect}
                  value={deliveryContactAddress}
                  label="subscriptions.fields.delivery_contact_address"
                  itemProps={{ initialValue: deliveryContactAddress }}
                />
              )}
            </SingleElementForm>
            {invoice_contact?.email && (
              <SingleElementFormV2
                itemProps={{
                  label: translate("contacts.email"),
                }}
                disableHoverBg
                view={<Text>{invoice_contact.email}</Text>}
              />
            )}
            {invoice_contact?.firstname && (
              <SingleElementFormV2
                itemProps={{
                  label: translate("contacts.firstname"),
                }}
                disableHoverBg
                view={<Text>{invoice_contact.firstname}</Text>}
              />
            )}
            {invoice_contact?.lastname && (
              <SingleElementFormV2
                itemProps={{
                  label: translate("contacts.lastname"),
                }}
                disableHoverBg
                view={<Text>{invoice_contact.lastname}</Text>}
              />
            )}
            {invoice_contact?.company && (
              <SingleElementFormV2
                itemProps={{
                  label: translate("contacts.company"),
                }}
                disableHoverBg
                view={<Text>{invoice_contact.company}</Text>}
              />
            )}
            {invoice_contact?.phone_numbers?.length > 0 && (
              <SingleElementFormV2
                itemProps={{
                  label: translate("contacts.add_phone_number"),
                }}
                disableHoverBg
                view={
                  <Space direction="vertical" size={"small"}>
                    {invoice_contact?.phone_numbers?.map((item) => (
                      <Text key={item.id}>
                        {item?.number} {item?.label && <Tag>{item?.label}</Tag>}
                      </Text>
                    ))}
                  </Space>
                }
              />
            )}
          </Card>
          <Flex justify="space-between" align="center">
            <Text>
              {translate("subscriptions.show.subscription")}:{" "}
              <Typography.Text style={{ fontWeight: 500 }}>
                {name}
              </Typography.Text>{" "}
            </Text>

            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={handleAddNewPhase}
              style={{ fontSize: "0.80rem", fontWeight: 500, padding: 7 }}
            >
              {translate("subscription_products.list.add")}
            </Button>
          </Flex>

          <Form {...formProps} layout="vertical">
            <Form.Item name={"phases"} noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Collapse
              items={items}
              defaultActiveKey={["1"]}
              expandIconPosition="end"
            />
          </Form>

          {issuesLeftWithProductNames.length > 0 && (
            <>
              <Typography.Title
                level={5}
                style={{
                  fontSize: "1.02em",
                  marginBottom: 5,
                  marginTop: 20,
                }}
              >
                {translate("subscriptions.issues_left.title")}
              </Typography.Title>
              <Table
                dataSource={issuesLeftWithProductNames}
                columns={columns}
                rowKey="productId"
                pagination={false}
              />
            </>
          )}

          <Modal
            title={translate("subscriptions.issues_left.modal.title")}
            open={isModalOpen}
            onOk={handleModalOk}
            onCancel={() => setIsModalOpen(false)}
            okText={translate("buttons.yes")}
            cancelText={translate("buttons.cancel")}
          >
            <p>{translate("subscriptions.issues_left.modal.message")}</p>
          </Modal>
        </Space>
      </Drawer>
      {children}
    </>
  );
};
export default SubscriptionsShow;
