import { useTranslate } from "@refinedev/core";
import { Button, Space, StepProps, Steps, Typography } from "antd";
import React from "react";
import useTeamFn from "../../hooks/useTeamFn";
import FirstStep from "./FirstStep";
import SelectSubscription from "./SelectSubscription";

type Props = {
  isFirstTeam?: boolean;
  newTeam: any;
  setNewTeam: React.Dispatch<any>;
};

const steps = ["name_your_team", "choose_subscription"];
export default function CreateTeam({
  isFirstTeam,
  newTeam,
  setNewTeam,
}: Props) {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [choosenPlan, setChoosenPlan] = React.useState<any>();

  const { createTeam } = useTeamFn();
  const handleFinish = async ({ teamName }: { teamName: string }) => {
    try {
      const res = await createTeam({ name: teamName });
      setNewTeam({ name: teamName, slug: res?.slug });
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.error("Error while creating team");
    }
  };
  const translate = useTranslate();

  // const items: StepProps[] = [
  //   {
  //     description: (
  //       <FirstStep
  //         disabled={currentStep !== 0}
  //         handleNext={(team) => {
  //           setCurrentStep(1);
  //           setNewTeam(team);
  //         }}
  //       />
  //     ),
  //     subTitle: (
  //       <Typography.Text
  //         disabled={currentStep !== 0}
  //         style={{ fontWeight: "500" }}
  //       >
  //         {translate("teams.name_your_team") + ":"}
  //       </Typography.Text>
  //     ),
  //     title: <Typography.Title type="secondary">1</Typography.Title>,
  //   },
  //   {
  //     title: (
  //       <Typography.Title disabled={currentStep < 1} type="secondary">
  //         2
  //       </Typography.Title>
  //     ),
  //     subTitle: (
  //       <Typography.Text
  //         disabled={currentStep < 1}
  //         style={{ fontWeight: "500" }}
  //       >
  //         {translate("teams.choose_subscription")}
  //       </Typography.Text>
  //     ),
  //     description: (
  //       <SelectSubscription
  //         handleOnChange={(item) => setChoosenPlan(item)}
  //         disabled={currentStep < 1}
  //         team={newTeam}
  //         handleNext={() => setCurrentStep((prev) => prev + 1)}
  //         handlePrev={() => setCurrentStep((prev) => prev - 1)}
  //       />
  //     ),
  //   },
  // ];
  const generateItems = (): StepProps[] => {
    const itemsList: StepProps[] = [];

    steps.forEach((step, index) => {
      if (isFirstTeam && step === "welcome") {
        itemsList.push({
          subTitle: (
            <Typography.Title level={2}>
              {translate("global.welcome")}
            </Typography.Title>
          ),
          description: (
            <Space direction="vertical">
              <Typography.Text
                disabled={currentStep !== index}
                style={{ fontWeight: "500" }}
              >
                {translate("teams.team_creation_text")}
              </Typography.Text>
              <br />
              <Button
                type="primary"
                disabled={currentStep !== index}
                onClick={() => {
                  setCurrentStep(1);
                }}
              >
                {translate("teams.account_continue_success_button")}
              </Button>
            </Space>
          ),
          title: (
            <Typography.Title style={{ marginInlineStart: 0 }} type="secondary">
              {index + 1}
            </Typography.Title>
          ),
        });
      }

      if (!isFirstTeam && step === "choose_subscription") {
        itemsList.push({
          title: (
            <Typography.Title disabled={currentStep < index} type="secondary">
              {index + 1}
            </Typography.Title>
          ),
          subTitle: (
            <Typography.Text
              disabled={currentStep < index}
              style={{ fontWeight: "500" }}
            >
              {translate("teams.choose_subscription")}
            </Typography.Text>
          ),
          description: (
            <SelectSubscription
              disabled={currentStep < index}
              team={newTeam?.name}
              handleNext={() => setCurrentStep((prev) => prev + 1)}
              handlePrev={() => setCurrentStep((prev) => prev - 1)}
              choosenPlan={choosenPlan}
              setChoosenPlan={setChoosenPlan}
              handleFinish={handleFinish}
            />
          ),
        });
      } else if (step === "name_your_team") {
        itemsList.push({
          description: (
            <FirstStep
              disabled={currentStep !== index}
              handleNext={(team) => {
                setCurrentStep(currentStep + 1);
                setNewTeam({ name: team });
              }}
              handlePrev={
                isFirstTeam ? () => setCurrentStep(currentStep - 1) : undefined
              }
              handleFinish={handleFinish}
            />
          ),
          subTitle: (
            <Space direction="vertical">
              {isFirstTeam && (
                <>
                  <Typography.Title level={2}>
                    {translate("global.welcome")}
                  </Typography.Title>
                  <Typography.Text
                    disabled={currentStep !== index}
                    style={{ fontWeight: "500" }}
                  >
                    {translate("teams.team_creation_text")}
                  </Typography.Text>
                </>
              )}
              <Typography.Text
                disabled={currentStep !== index}
                style={{ fontWeight: "500" }}
              >
                {translate(`teams.${step}`) + ":"}
              </Typography.Text>
            </Space>
          ),
          title: (
            <Typography.Title type="secondary">{index + 1}</Typography.Title>
          ),
        });
      }
    });

    return itemsList ?? [];
  };
  const items = generateItems();
  return (
    <>
      {isFirstTeam ? (
        <Space style={{ width: "80%" }} direction="vertical">
          <FirstStep
            handleNext={(team) => {
              setCurrentStep(currentStep + 1);
              setNewTeam({ name: team });
            }}
            disabled={false}
            isFirstTeam
            handleFinish={handleFinish}
          />
        </Space>
      ) : (
        <Steps
          style={{ width: "80%" }}
          direction="vertical"
          current={currentStep}
          // progressDot={(dot, { index, status }) => {
          //   console.log(index, status);
          //   return dot;
          // }}
          items={
            choosenPlan && choosenPlan?.price
              ? [
                  ...items,
                  //   {
                  //     title: (
                  //       <Typography.Title disabled={currentStep < items.length-1} type="secondary">
                  //         3
                  //       </Typography.Title>
                  //     ),

                  //     description: (
                  //       <Space direction="vertical">
                  //         <Button>Continue to payment</Button>
                  //         <Button type="primary">Create Team</Button>
                  //       </Space>
                  //     ),
                  //   },
                ]
              : items
          }
        ></Steps>
      )}
    </>
  );
}
