import { ThemeConfig } from "antd";

export const themeProvider: ThemeConfig = {
  token: {
    fontSize: 16,
    lineHeight: 1.5,
  },
  components: {
    Layout: {
      // bodyBg: "#f9fafc",
      bodyBg: "#fff",
      headerBg: "#fff",
      //siderBg: "#096DD9",
      siderBg: "#fbfbfb",
      headerPadding: "0 24px",
    },
    Card: {
      borderRadiusLG: 4,
    },
    List: {
      metaMarginBottom: 40,
      titleMarginBottom: 40,
    },
    Drawer: {
      // colorBgElevated: "rgba(245, 247, 249, 1)",
    },
    Menu: {
      itemSelectedBg: "#ebebeb",
      itemSelectedColor: "rgba(0, 0, 0, 1)",
      itemColor: "#202020",
      itemHoverColor: "#202020",
      itemHoverBg: "#ebebeb",
      subMenuItemBg: "rgba(0, 0, 0, 0)",
      // popupBg: "#096DD9",
      popupBg: "#FCFAF8",
      itemActiveBg: "rgba(0, 0, 0, 0.09)",
    },
    Radio: {
      buttonSolidCheckedBg: "#1890FF",
    },
    Table: {
      colorBgContainer: "#fff",
      //headerBg: "rgba(245, 247, 249, 1)",
      // headerBg: "rgba(232, 241, 254, 1)",
      borderColor: "#F5F5F5",
      headerBorderRadius: 0,
    },
    Button: {
      colorPrimary: "#1890FF",
      controlHeight: 40,
      controlHeightSM: 31.6,
    },
    Tabs: {
      colorFillAlter: "#fff",
      margin: 0,
      cardGutter: 8,
      colorBorderSecondary: "rgba(240, 240, 240, 1)",
      itemSelectedColor: "#000",
      itemColor: "#595959",
    },
    Input: {
      colorBgContainerDisabled: "#F3F8FD",
      colorTextDisabled: "#98A8B9",
    },
    Select: {
      colorTextDisabled: "#98A8B9",
      colorBgContainerDisabled: "#F3F8FD",
    },
    Modal: {
      colorBgMask: "rgba(0,0,0,0.08)",
    },
    Timeline: {
      dotBg: "transparent",
    },
  },
};
