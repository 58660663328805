import {
  useThemedLayoutContext,
  type RefineThemedLayoutV2HeaderProps,
} from "@refinedev/antd";
import {
  Layout as AntdLayout,
  Badge,
  Button,
  Dropdown,
  Flex,
  MenuProps,
  Space,
  Tag,
} from "antd";

import {
  ApartmentOutlined,
  LogoutOutlined,
  SettingOutlined,
  StarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  useGetIdentity,
  useList,
  useLogout,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import React, { useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import useModules from "../../hooks/useModules";
import AccountShow from "../../pages/account/show";

export const HeaderHeadless: React.FC<RefineThemedLayoutV2HeaderProps> = () => {
  const [openAccountModal, setOpenAccountModal] = useState<boolean>(false);
  const { teamMenuItems, refetchTeams } = useUser();
  const { fetchModuleData } = useModules();
  const translate = useTranslate();
  const { list, replace } = useNavigation();
  const { mutate: logout } = useLogout();

  const { data: me } = useGetIdentity<any>();
  const { data: invitations } = useList({
    resource: "user_invitations",
    filters: [
      {
        field: "email",
        value: me?.email ?? "",
        operator: "eq",
      },
    ],
    meta: {
      schema: "basejump",
      select:
        "*, team:accounts!basejump_user_invitations_account_id_fkey(*), invited_by:accounts!basejump_user_invitations_invited_by_account_fkey(*)",
    },
  });

  const { setMobileSiderOpen } = useThemedLayoutContext();

  const itemsTeam = teamMenuItems?.flatMap((item: any) => {
    return [
      {
        ...item,
        icon: <ApartmentOutlined />,
        label: (
          <a
            rel="noopener noreferrer"
            onClick={() => {
              setMobileSiderOpen(false);
              refetchTeams;
              fetchModuleData(item.slug);
            }}
          >
            {item.label}
          </a>
        ),
      },
    ];
  });

  itemsTeam?.push(
    {
      type: "divider",
    },
    {
      key: "manage_workspace",
      icon: <SettingOutlined />,
      label: (
        <a rel="noopener noreferrer">{translate("teams.manage_workspaces")}</a>
      ),
      onClick: () => replace("workspaces"),
    }
  );
  const userMenuItems: MenuProps["items"] = [
    {
      label: translate("buttons.account", "Account settings"),
      onClick: () => setOpenAccountModal(true),
      icon: <UserOutlined />,
      key: "u",
    },
    {
      type: "divider",
    },
    {
      type: "group",
      label: translate("teams.workspaces"),
    },
    ...(itemsTeam ?? []),
    { type: "divider" },
    {
      label: translate("buttons.logout"),
      icon: <LogoutOutlined />,
      onClick: () => {
        logout();
      },
      key: "q",
    },
  ];

  return (
    <AntdLayout.Header
      style={{
        background: "#fff",
        borderBottom: "1.5px solid #eee",
      }}
    >
      <Flex style={{ width: "100%" }} justify="flex-end" align="center">
        <Space size={"small"}>
          <Tag style={{ display: "none" }}>
            <StarOutlined style={{ color: "orange" }} />{" "}
            {translate("settings.upgrade", "Upgrade to Pro")}
          </Tag>

          <Dropdown
            placement={"bottomRight"}
            menu={{ items: userMenuItems }}
            trigger={["click"]}
          >
            {/* <Badge size="small" count={invitations?.data?.length}> */}
            <Button
              type={"default"}
              //onClick={(e) => e.preventDefault()}
              shape="circle"
            >
              <Space>
                <Space direction={"horizontal"}>
                  <UserOutlined />
                </Space>
              </Space>
            </Button>
            {/* </Badge> */}
          </Dropdown>
        </Space>
      </Flex>
      <AccountShow
        openModal={openAccountModal}
        setOpenModal={() => setOpenAccountModal(false)}
      />
    </AntdLayout.Header>
  );
};
