import { FieldTimeOutlined } from "@ant-design/icons";
import { useTable } from "@refinedev/antd";
import { BaseKey, useTranslate } from "@refinedev/core";
import { Button, Modal, Table, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { logErrorMessage, logSuccessMessage } from "../../utility/automations";
type Props = {
  id: BaseKey | undefined;
};
export default function LogsList({ id }: Props) {
  const [modal, setModal] = useState(false);
  const [errorData, setErrorData] = useState<string | object>("{}");
  const translate = useTranslate();
  const { tableProps } = useTable({
    resource: "logs",
    filters: {
      permanent: [
        { field: "resource_type", operator: "eq", value: "automations" },
        { field: "resource_id", operator: "eq", value: id },
      ],
    },
  });
  useEffect(() => {
    if (!modal) {
      setErrorData("");
    }
  }, [modal]);
  return (
    <>
      <Modal
        open={modal}
        cancelButtonProps={{ hidden: true }}
        title="Data Message"
        onOk={() => {
          setModal(false);
        }}
        onCancel={() => setModal(false)}
      >
        <JsonView
          data={errorData}
          shouldExpandNode={allExpanded}
          style={defaultStyles}
        />
      </Modal>
      <Table
        {...tableProps}
        rowKey={(record) => record.id ?? ""}
        rowClassName={"clickableRow"}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
      >
        <Table.Column
          dataIndex="resource_id"
          title={translate("automations.logs.id")}
        />
        <Table.Column
          dataIndex="type"
          title={translate("automations.logs.type")}
          render={(value: "error" | "success") => (
            <Tag color={value == "error" ? "red" : "green"}>
              {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
            </Tag>
          )}
        />
        <Table.Column
          dataIndex="data"
          title={translate("automations.logs.data")}
          render={(value: any, record: any) => (
            <>
              <Button
                type="link"
                onClick={() => {
                  if (record.type == "error") {
                    setErrorData(logErrorMessage(value, record));
                  } else {
                    setErrorData(logSuccessMessage(value, record));
                  }
                  setModal(value);
                }}
              >
                show message
              </Button>
            </>
          )}
        />
        <Table.Column
          dataIndex="created_at"
          title={translate("automations.logs.created_at")}
          render={(value) => (
            <>
              {value ? (
                <>
                  <FieldTimeOutlined />
                  {new Date(value).toLocaleString(
                    translate("pipelines.date_locale")
                  )}
                </>
              ) : (
                <Typography.Text type="secondary">
                  {translate("automations.latest_run_not")}
                </Typography.Text>
              )}
            </>
          )}
        />
      </Table>
    </>
  );
}
