import { useTable } from "@refinedev/antd";
import { Button, Flex, Popconfirm, Table } from "antd";
import React from "react";
import { useGetIdentity, useNavigation, useTranslate } from "@refinedev/core";
import { useTeamInvitation } from "../../hooks/useTeamInvitation";

type Props = {
  navigateAfterTeamAcceptance?: boolean;
};
export default function Invitations({ navigateAfterTeamAcceptance }: Props) {
  const translate = useTranslate();
  const { data } = useGetIdentity<any>();
  const { push } = useNavigation();

  const { declineInvitation, acceptInvitation } = useTeamInvitation();
  const { tableProps, tableQuery } = useTable({
    resource: "user_invitations",
    filters: {
      permanent: [
        {
          field: "email",
          value: data?.email ?? "",
          operator: "eq",
        },
      ],
    },
    syncWithLocation: false,
    meta: {
      schema: "basejump",
      select:
        "*, team:accounts!basejump_user_invitations_account_id_fkey(*), invited_by:accounts!basejump_user_invitations_invited_by_account_fkey(*)",
    },
  });

  const handleAccept = async (invitation: any) => {
    try {
      await acceptInvitation(invitation.id);

      if (navigateAfterTeamAcceptance) push(`/${invitation.team.slug}`);
    } catch (error) {
      //
    }
  };

  return (
    <Table
      {...tableProps}
      dataSource={tableQuery.data?.data}
      columns={[
        {
          title: translate("account.invitation.team"),
          dataIndex: ["team", "name"],
          key: "id",
        },
        {
          title: translate("account.invitation.invited_by"),
          dataIndex: ["invited_by", "name"],
          key: "id",
        },

        {
          title: translate("account.invitation.invited_on"),
          dataIndex: ["created_at"],

          render: (value) => (
            <>
              {new Date(value).toLocaleString(
                translate("pipelines.date_locale")
              )}
            </>
          ),
        },
        {
          title: translate("account.invitation.actions"),
          dataIndex: "id",
          key: "id",
          render: (value, record) => {
            return (
              <Flex gap={10}>
                <Popconfirm
                  title={translate("teams.invitation.decline_title")}
                  description={translate(
                    "teams.invitation.decline_description"
                  )}
                  onConfirm={async () => {
                    if (record?.id) {
                      await declineInvitation(value);
                    }
                  }}
                  okText={translate("buttons.yes", "Yes")}
                  cancelText={translate("buttons.no", "No")}
                >
                  <Button type="primary" danger size="small">
                    {translate("teams.decline")}
                  </Button>
                </Popconfirm>
                <Button size="small" onClick={() => handleAccept(record)}>
                  {translate("teams.accept")}
                </Button>
              </Flex>
            );
          },
        },
      ]}
    ></Table>
  );
}
