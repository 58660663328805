import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import de from "./locales/de/common.json";
import en from "./locales/en/common.json";

const supportedLanguages = ["en", "de"];

// Get the language from localStorage or use the browser's language
const storedLanguage = localStorage.getItem("language");
const userLanguage = navigator.language.split("-")[0];
const selectedLanguage =
  storedLanguage ||
  (supportedLanguages.includes(userLanguage) ? userLanguage : "en");

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    lng: selectedLanguage,
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
    },
    supportedLngs: supportedLanguages,
    fallbackLng: supportedLanguages,
    detection: {
      order: ["localStorage", "navigator"],
    },
  });

// Save the language to localStorage when it changes
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("language", lng);
});

export default i18n;
