import { useTable } from "@refinedev/antd";
import {
  useGetIdentity,
  useNavigation,
  useNotification,
  useTranslate,
} from "@refinedev/core";
import { Button, List, Popconfirm } from "antd";
import { useTeamInvitation } from "../../hooks/useTeamInvitation";
import { Trans } from "react-i18next";

type Props = {
  navigateAfterTeamAcceptance?: boolean;
};
export default function InvitationsList({
  navigateAfterTeamAcceptance,
}: Props) {
  const translate = useTranslate();
  const { data } = useGetIdentity<any>();
  const { push } = useNavigation();
  const { open } = useNotification();
  const { declineInvitation, acceptInvitation } = useTeamInvitation();
  const { tableQuery } = useTable({
    resource: "user_invitations",
    pagination: {
      mode: "off",
    },
    filters: {
      permanent: [
        {
          field: "email",
          value: data?.email ?? "",
          operator: "eq",
        },
      ],
    },
    syncWithLocation: false,
    meta: {
      schema: "basejump",
      select:
        "*, team:accounts!basejump_user_invitations_account_id_fkey(*), invited_by:accounts!basejump_user_invitations_invited_by_account_fkey(*)",
    },
  });

  const handleAccept = async (invitation: any) => {
    try {
      await acceptInvitation(invitation.id);

      if (navigateAfterTeamAcceptance) push(`/${invitation.team.slug}`);
    } catch (error) {
      //
    }
  };

  return tableQuery.data?.data && tableQuery.data?.data?.length > 0 ? (
    <List
      dataSource={tableQuery.data?.data}
      renderItem={(item: any) => {
        return (
          <List.Item
            actions={[
              <Popconfirm
                title={translate("teams.invitation.decline_title")}
                description={translate("teams.invitation.decline_description")}
                onConfirm={async () => {
                  if (item.id) {
                    await declineInvitation(item?.id);
                  }
                }}
                okText={translate("buttons.yes", "Yes")}
                cancelText={translate("buttons.no", "No")}
              >
                <Button type="primary" danger size="small">
                  {translate("teams.decline")}
                </Button>
              </Popconfirm>,
              <Button
                size="small"
                onClick={async () => {
                  try {
                    await handleAccept(item);
                    open?.({
                      description: translate("notifications.success"),
                      message: translate("notifications.accceptSuccess"),
                      type: "success",
                    });
                  } catch (error) {
                    open?.({
                      description: translate("alerts.toast.error"),
                      message: translate("notifications.revokeSuccess"),
                      type: "error",
                    });
                  }
                }}
              >
                {translate("teams.accept")}
              </Button>,
            ]}
          >
            <div style={{ textWrap: "nowrap" }}>
              <Trans
                i18nKey="account.invitation.trans_invitation"
                values={{
                  user: item.invited_by?.name ?? "",
                  team: item.team?.name || "",
                }}
                components={{ bold: <strong /> }}
              />
              <strong style={{ textWrap: "nowrap" }}>
                {new Date(item.created_at).toLocaleString(
                  translate("pipelines.date_locale")
                )}
              </strong>
            </div>
          </List.Item>
        );
      }}
    />
  ) : null;
}
