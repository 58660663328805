import React, { useMemo } from "react";
import InvitationsTable from "../../components/account/invitations";
import { Button, Flex, Space } from "antd";
import useTeam from "../../hooks/useTeam";
import { useNavigate } from "react-router-dom";

export default function Invitations() {
  const navigate = useNavigate();
  const { allTeams } = useTeam();
  const hasTeam = useMemo(
    () => allTeams?.some((team) => !team.personal_account),
    [allTeams]
  );
  return (
    <Flex
      vertical
      align="center"
      justify="center"
      style={{ height: "100vh", minWidth: "900px" }}
      gap={20}
    >
      <InvitationsTable navigateAfterTeamAcceptance />
      {hasTeam && (
        <Space>
          <Button type="text" onClick={() => navigate(`/`)}>
            Go to dashboard
          </Button>
        </Space>
      )}
      <Space>
        <Button type="text" onClick={() => navigate(`/team/create`)}>
          Create Workspace
        </Button>
      </Space>
    </Flex>
  );
}
