import { PlusOutlined, RobotOutlined } from "@ant-design/icons";
import { List, useTable } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Spin } from "antd";
import { PropsWithChildren } from "react";
import AutomationListPage from "../../components/automations/list";
import CustomBreadcrumb from "../../components/common/CustomBreadcrumb";
import { useUser } from "../../contexts/ContextProvider";

export default function AutomationsList({ children }: PropsWithChildren) {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { tableProps, tableQuery } = useTable({
    resource: "automations",
    sorters: { initial: [{ field: "created_at", order: "desc" }] },
    filters: {
      initial: [
        {
          field: "account",
          value: currentTeam?.account_id,
          operator: "eq",
        },
      ],
    },
    meta: {
      select: `*`,
    },
  });
  if (tableProps.loading) return <Spin />;
  const breadcrumbItems = [
    {
      icon: <RobotOutlined />,
      title: translate("automations.title"),
    },
  ];
  return (
    <>
      <List
        title={<CustomBreadcrumb items={breadcrumbItems} />}
        breadcrumb={false}
        createButtonProps={{
          icon: <PlusOutlined />,
          title: translate("buttons.create"),
          hideText: true,
          size: "small",
        }}
      >
        <AutomationListPage tableProps={tableProps} data={tableQuery.data} />
      </List>
      {children}
    </>
  );
}
