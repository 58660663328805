import { useSelect } from "@refinedev/antd";
import { useUser } from "../contexts/ContextProvider";
import { BillingType } from "../interfaces/phase.interface";

export const useProductSelect = () => {
  const { currentTeam } = useUser();

  return useSelect<{
    description: string;
    quantity: number;
    billing_interval: BillingType;
    invoice_interval: number;
    sales_tax: number;
    currency: string;
    netto_price: any;
    id: number | string;
    title: string;
    variant: { value: string }[]; // Adjusted to include `variant` as an array of objects
  }>({
    resource: "product_items",
    optionLabel: (item) => {
      const variantLabel = item.variant?.map((v) => v.value).join(", ") || "";
      return `${item?.title} ${variantLabel ? `(${variantLabel})` : ""}`;
    },
    optionValue: "id",
    meta: {
      select: "*",
    },
    filters: [
      {
        field: "account",
        operator: "eq",
        value: currentTeam?.account_id,
      },
      {
        field: "configured_variants",
        operator: "null",
        value: null,
      },
    ],
  });
};
