import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { supabaseClient } from "../../../utility";
import { RegisterPage } from "../RegisterPage";
import { useTranslate } from "@refinedev/core";
import { Spin } from "antd";

export default function NotLogedInInvitationWrapper() {
  const [isUser, setIsUser] = useState<any>();
  const t = useTranslate();
  const [searchParams] = useSearchParams();
  const paramEmail = searchParams.get("sent_to");
  const team = searchParams.get("team");

  const getUser = useCallback(async (email) => {
    const { data: response } = await supabaseClient.rpc("is_email_registered", {
      email_input: email,
    });

    setIsUser(response ?? null);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (paramEmail) {
      getUser(paramEmail);
    }
  }, [getUser, paramEmail]);

  if (isUser) {
    navigate(`/login?to=${encodeURIComponent("/team/invitations")}`);
  }
  return (
    <>
      <RegisterPage
        wrapperProps={{
          style: {
            background: "url('/images/authpage-register.jpg')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
          },
        }}
        title={t(
          "pages.register.welcome_invitation",
          { team: team },
          "Sign up now and try Tellie for free and without obligation."
        )}
        formProps={{ initialValues: { email: paramEmail } }}
      />
    </>
  );
}
