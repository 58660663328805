import { useForm } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { RuleGroupType } from "react-querybuilder";
import CustomQueryBuilder from "../../../../../components/contacts/filter-group/CustomQueryBuilder";
import { useUser } from "../../../../../contexts/ContextProvider";

type Props = {
  isModalVisible?: boolean;
  setIsModalVisible?: (boolean) => void;
  productId?: string;
};
export default function ProductIssuesExportCreate({
  isModalVisible,
  setIsModalVisible,
  productId,
}: Props) {
  const [query, setQuery] = useState<RuleGroupType>({
    combinator: "and",
    rules: [],
  });
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { formProps, saveButtonProps, onFinish } = useForm({
    resource: "product_issue_exports",
  });
  useEffect(() => {
    formProps.form?.setFieldValue("rule", query);
  }, [query]);
  return (
    <Modal
      title="Create Export Lists"
      open={isModalVisible}
      onCancel={() => {
        setIsModalVisible?.(false);
        formProps.form?.resetFields();
      }}
      okButtonProps={{ ...saveButtonProps }}
      destroyOnClose={true}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(value) => {
          onFinish({
            ...value,
            account: currentTeam?.account_id,
            product_item: productId,
          });
          setIsModalVisible && setIsModalVisible(false);
          formProps.form?.resetFields();
        }}
      >
        <Form.Item
          name={"name"}
          label={translate("product_items.product_issue_exports.name")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={"rule"}
          label={translate("product_items.product_issue_exports.rule")}
        >
          <CustomQueryBuilder handleChange={(value) => setQuery(value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
