const GmailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    fontSize={40}
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="#fff"
      d="M2 11.956c0-3.485 0-5.228.678-6.559a6.222 6.222 0 0 1 2.72-2.719C6.727 2 8.47 2 11.955 2h8.088c3.485 0 5.228 0 6.559.678a6.222 6.222 0 0 1 2.719 2.72C30 6.727 30 8.47 30 11.955v8.088c0 3.485 0 5.228-.678 6.559a6.222 6.222 0 0 1-2.72 2.719C25.273 30 23.53 30 20.045 30h-8.088c-3.485 0-5.228 0-6.559-.678a6.222 6.222 0 0 1-2.719-2.72C2 25.273 2 23.53 2 20.045v-8.088Z"
    />
    <path
      fill="#EA4335"
      d="m22.052 8.523-5.988 4.672L9.94 8.523v.001l.008.007v6.542l6.047 4.774 6.057-4.59V8.524Z"
    />
    <path
      fill="#FBBC05"
      d="m23.623 7.386-1.572 1.137v6.735l4.947-3.799V9.171s-.6-3.268-3.375-1.785Z"
    />
    <path
      fill="#34A853"
      d="M22.05 15.258v8.734h3.793s1.079-.11 1.157-1.34V11.458l-4.95 3.799Z"
    />
    <path
      fill="#C5221F"
      d="M9.948 24v-8.927l-.008-.006L9.948 24ZM9.94 8.524l-1.564-1.13C5.602 5.91 5 9.177 5 9.177v2.288l4.94 3.602V8.524Z"
    />
    <path fill="#C5221F" d="M9.94 8.524v6.543l.008.006V8.531l-.008-.007Z" />
    <path
      fill="#4285F4"
      d="M5 11.467v11.192A1.35 1.35 0 0 0 6.157 24h3.792l-.009-8.933-4.94-3.6Z"
    />
  </svg>
);
export default GmailIcon;
