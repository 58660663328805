import { useForm } from "@refinedev/antd";
import {
  useCreate,
  useGetIdentity,
  useNavigation,
  useNotification,
  useTranslate,
} from "@refinedev/core";
import { Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";
import MagicIcon from "../../assets/icons/magic";
import { useUser } from "../../contexts/ContextProvider";
import { supabaseClient } from "../../utility";

type Props = {
  onSuccess?: (data: any) => void;
  onClose?: () => void;
  open?: boolean;
};
const CreateContactModal = ({ onSuccess, open, onClose }: Props) => {
  const [requiredField, setRequiredField] = useState<boolean>(true);
  const [valueChanged, setValueChanged] = useState<boolean>(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const { mutateAsync: createMutate } = useCreate();
  const { data: me } = useGetIdentity<any>();
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const [salutationPlaceholder, setSalutationPlaceholder] =
    useState<string>("");
  const { list } = useNavigation();
  const { form, saveButtonProps, redirect } = useForm();
  const { open: openNotification } = useNotification();

  useEffect(() => {
    if (
      (form.getFieldValue("lastname") != null &&
        form.getFieldValue("lastname") != "") ||
      (form.getFieldValue("company_name") != null &&
        form.getFieldValue("company_name") != "")
    ) {
      setRequiredField(false);
    } else {
      setRequiredField(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueChanged]);
  const generateCustomerNumber = async () => {
    if (!currentTeam?.account_id) return;
    try {
      const { data, error } = await supabaseClient.rpc(
        "get_next_customer_number",
        {
          account_id: currentTeam.account_id,
        }
      );
      if (error) {
        openNotification?.({
          description: translate("alerts.errorGeneratingCustomerNumber"),
          message: error.message,
          type: "error",
        });
      } else {
        form.setFieldsValue({ customer_number: data });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal
      open={open == undefined ? true : open}
      maskClosable={false}
      centered
      title={translate("contacts.create_contact")}
      onCancel={() => {
        onClose ? onClose() : list("contacts", "replace");
      }}
      okText={translate("buttons.save")}
      okButtonProps={{
        ...saveButtonProps,
        loading: saveButtonLoading,
      }}
      styles={{ body: { maxHeight: 600, overflow: "auto" } }}
      width={760}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={async (values: any) => {
          try {
            setSaveButtonLoading(true);
            const contactEntity = await createMutate({
              resource: "contacts",
              meta: {
                select: "*",
              },
              values: {
                email: values.email,
                job_title: values.job_title,
                type: values.type,
                name_prefix: values.name_prefix,
                salutation: values.salutation,
                override_salutation: values.override_salutation,
                url: values.url,
                company_name: values.company_name,
                company_line_2: values.company_line_2,
                customer_number:
                  values.customer_number === "" ? null : values.customer_number,
                firstname: values.firstname,
                lastname: values.lastname,
                user: me.id,
                account: currentTeam?.account_id,
              },
              errorNotification: (error: any, values: any, resource) => {
                if (
                  error?.message?.includes("unique_customer_number_per_account")
                )
                  return {
                    message: translate("alerts.errorGeneratingCustomerNumber"),
                    description: translate("alerts.submitError.title"),
                    type: "error",
                  };
              },
            });
            if (values?.number)
              await createMutate({
                resource: "phone_numbers",
                meta: {
                  select: "*",
                },
                values: {
                  contact: contactEntity?.data?.id,
                  label: values?.label,
                  number: values?.number,
                  order: 1,
                  account: currentTeam?.account_id,
                },
                successNotification: false,
              });
            setSaveButtonLoading(false);
            if (onSuccess) {
              onSuccess(contactEntity.data);
            } else {
              redirect("show", contactEntity.data.id);
            }
          } catch (error) {
            setSaveButtonLoading(false);
            Promise.reject(error);
          }
        }}
      >
        <Row gutter={12}>
          <Col span={4}>
            <Form.Item
              label={translate("contacts.title")}
              name={["name_prefix"]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label={translate("contacts.firstname")}
              name={["firstname"]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label={translate("contacts.lastname")}
              name={["lastname"]}
              rules={[
                {
                  required: requiredField,
                  message: "Please input your last name or company name",
                },
              ]}
            >
              <Input onChange={() => setValueChanged(!valueChanged)} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate("contacts.salutation")}
              name={["salutation"]}
            >
              <Select
                onChange={(e) => {
                  setSalutationPlaceholder(
                    e === "not_specified"
                      ? "Hallo"
                      : "Sehr geehrte" +
                          (e === "mr" ? "r Herr" : e === "mrs" ? " Frau" : "")
                  );
                }}
                placeholder={translate("contacts.select_salutation")}
                optionFilterProp="children"
                options={[
                  {
                    value: "not_specified",
                    label: translate("contacts.salutation_keine_angabe"),
                  },
                  {
                    value: "mr",
                    label: translate("contacts.salutation_male"),
                  },
                  {
                    value: "mrs",
                    label: translate("contacts.salutation_female"),
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate("contacts.override_salutation")}
              name={["override_salutation"]}
            >
              <Input placeholder={salutationPlaceholder} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate("contacts.job_title")}
              name={["job_title"]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item
              label={translate("contacts.customer_number")}
              name="customer_number"
            >
              <Input
                addonAfter={
                  <MagicIcon
                    onClick={generateCustomerNumber}
                    style={{ cursor: "pointer" }}
                  />
                }
              />
            </Form.Item>
          </Col>
          <Col md={9}>
            <Form.Item
              label={translate("contacts.company")}
              name={["company_name"]}
              rules={[
                {
                  required: requiredField,
                  message: "Please input your company name or lastname",
                },
              ]}
            >
              <Input onChange={() => setValueChanged(!valueChanged)} />
            </Form.Item>
          </Col>
          <Col md={9}>
            <Form.Item
              label={translate("contacts.company_line_2")}
              name={["company_line_2"]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={translate("contacts.email")}
              name={["email"]}
              rules={[
                {
                  required: false,
                  type: "email",
                  message: translate("account.valid_email"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={translate("contacts.url")} name={["url"]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6} align={"bottom"}>
          <Col span={12}>
            <Form.Item
              name={"number"}
              label={translate("contacts.phone_singular")}
            >
              <Input placeholder={translate("contacts.number")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"label"}>
              <Input placeholder={translate("contacts.description")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateContactModal;
