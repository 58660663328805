import {
  ApartmentOutlined,
  DeleteOutlined,
  MailOutlined,
  MoreOutlined,
  PlaySquareOutlined,
  RobotOutlined,
  SettingOutlined,
  StopOutlined,
  SyncOutlined,
  WarningFilled,
} from "@ant-design/icons";
import { List, useForm } from "@refinedev/antd";
import {
  useDelete,
  useNavigation,
  useResourceParams,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import {
  Button,
  Drawer,
  DrawerProps,
  Dropdown,
  Popconfirm,
  Popover,
  Space,
  Spin,
  Tabs,
} from "antd";
import { TabsProps } from "antd/lib";
import {
  forwardRef,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import FlowBuilder, {
  INode,
  IRegisterNode,
  NodeContext,
} from "react-flow-builder";
import AutomationCreateUpdate from "../../components/automations/AutomationCreateUpdate";
import InfoAutomation from "../../components/automations/InfoAutomation";
import LogsList from "../../components/automations/LogsList";
import {
  ConditionDisplay,
  ConditionForm,
} from "../../components/automations/nodes/Condition";
import {
  SendMailDisplay,
  SendMailForm,
} from "../../components/automations/nodes/SendMail";
import CustomBreadcrumb from "../../components/common/CustomBreadcrumb";
import { useUser } from "../../contexts/ContextProvider";
import { editNode } from "../../utility/automations";
import "../pipelines/automation/index.css";

const StartNodeDisplay: React.FC = () => {
  const node = useContext(NodeContext);
  return <div className="start-node">{node.name}</div>;
};

const EndNodeDisplay: React.FC = () => {
  const node = useContext(NodeContext);
  return <div className="end-node">{node.name}</div>;
};
const IterateDisplay: React.FC = () => {
  const node = useContext(NodeContext);
  return <div className="condition-node">{node.name}</div>;
};

const DrawerComponent = forwardRef<HTMLDivElement, DrawerProps>(
  function DrawerComponent({ visible, ...props }, ref) {
    return <Drawer open={visible} {...props} panelRef={ref} />;
  }
);

const defaultNodes = [
  {
    id: "node-0d9d4733-e48c-41fd-a41f-d93cc4718d97",
    type: "start",
    name: "start",
    path: ["0"],
  },
  {
    id: "node-b106675a-5148-4a2e-aa86-8e06abd692d1",
    type: "end",
    name: "end",
    path: ["2"],
  },
];
export default function AutomationsEdit({ children }: PropsWithChildren) {
  const [nodes, setNodes] = useState<INode[]>(defaultNodes);
  const [modalOpen, setModalOpen] = useState(false);
  const { mutate: deleteMutation } = useDelete();
  const { currentTeam } = useUser();

  const translate = useTranslate();
  const { list } = useNavigation();

  const closeEditModal = () => {
    setModalOpen(false);
  };
  const { id } = useResourceParams();
  const { mutateAsync: update } = useUpdate();
  const { query } = useForm({
    action: "edit",
    id,
  });
  const registerNodes: IRegisterNode[] = [
    {
      type: "start",
      name: "Start node",
      displayComponent: StartNodeDisplay,
      isStart: true,
    },
    {
      type: "end",
      name: "End node",
      displayComponent: EndNodeDisplay,
      isEnd: true,
    },
    {
      type: "condition",
      name: "Condition 1",
      displayComponent: ConditionDisplay,
      configComponent: ConditionForm,
      showPracticalBranchNode: true,
      showPracticalBranchRemove: true,
      addConditionIcon: <ApartmentOutlined />,
    },
    {
      type: "send_mail",
      name: "Send Mail",
      displayComponent: SendMailDisplay,
      configComponent: (props) => <SendMailForm {...props} nodes={nodes} />,
      addIcon: <MailOutlined />,
      initialNodeData: {
        data: {},
      },
    },
    {
      type: "branch",
      name: "Conditional branch",
      conditionNodeType: "condition",
    },
    {
      type: "iterator",
      name: "Iterator",
      isLoop: true,
      addIcon: <SyncOutlined />,
      displayComponent: IterateDisplay,
    },
  ];
  const handleChange = (nodes: INode[], changeEvent, targetNode) => {
    if (id) {
      let hasError =
        nodes.some((item) => item.validateStatusError) || nodes.length <= 2;
      if (changeEvent == "add-node__send_mail") {
        editNode(nodes, targetNode, {
          ...targetNode,
          validateStatusError: true,
        });
        hasError = true;
      }

      setNodes(nodes);
      update({
        id,
        values: {
          data: nodes,
          hasError,
          ...(hasError ? { status: false } : {}),
        },
        resource: "automations",
        successNotification: false,
      });
    }
  };
  useEffect(() => {
    if (query?.data?.data.data) {
      setNodes(query?.data?.data.data);
    }
  }, [query?.data]);
  if (query?.isLoading) return <Spin />;
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Flow",
      children: (
        <>
          <InfoAutomation data={query?.data?.data} />
          <FlowBuilder
            nodes={nodes}
            onChange={handleChange}
            registerNodes={registerNodes}
            DrawerComponent={DrawerComponent}
            PopoverComponent={({ visible, onVisibleChange, ...props }) => (
              <Popover
                {...props}
                open={visible}
                onOpenChange={onVisibleChange}
              />
            )}
            PopconfirmComponent={Popconfirm}
            backgroundColor={undefined}
            showArrow={true}
            // onAddNodeSuccess={async (type, node) => {
            //   if (type == "send_mail") {
            //     const toUpdate = queryResult?.data?.data?.data?.find(
            //       (it) => it.id == node.id
            //     );

            //     const updatedList = queryResult?.data?.data?.data.map(
            //       (item) => {
            //         if (item.id == toUpdate.id) {
            //           return { ...item, validateStatusError: true };
            //         }
            //         return item;
            //       }
            //     );
            //     if (id)
            //       await update({
            //         id,
            //         values: {
            //           data: updatedList,
            //           hasError: true,
            //         },
            //         resource: "automations",
            //         successNotification: false,
            //       });
            //   }
            // }}
          />
        </>
      ),
    },
    {
      key: "2",
      label: query?.data?.data?.hasError ? (
        <>
          <WarningFilled style={{ color: "orange" }} /> Logs
        </>
      ) : (
        "Logs"
      ),
      children: <LogsList id={id} />,
    },
  ];
  const { name } = query?.data?.data || {};
  const breadcrumbItems = [
    {
      icon: <RobotOutlined />,
      title: translate("automations.title"),
      url: `/${currentTeam?.slug}/automations`,
    },
    {
      title: name,
    },
  ];
  return (
    <>
      <List
        breadcrumb={false}
        title={<CustomBreadcrumb items={breadcrumbItems} />}
        headerButtons={
          <>
            <Popconfirm
              title={translate("automations.change_status")}
              description={translate("automations.change_status_description")}
              onConfirm={() => {
                if (id) {
                  update({
                    id: id,
                    resource: "automations",
                    values: {
                      status: !query?.data?.data.status,
                    },
                  });
                }
              }}
              okText={translate("buttons.yes")}
              cancelText={translate("buttons.no")}
            >
              <Button
                type={!query?.data?.data.status ? "primary" : "default"}
                disabled={query?.data?.data.hasError}
                size="small"
                icon={
                  query?.data?.data.status ? (
                    <StopOutlined />
                  ) : (
                    <PlaySquareOutlined />
                  )
                }
              />
            </Popconfirm>
            <Dropdown
              menu={{
                items: [
                  {
                    label: (
                      <a onClick={() => setModalOpen(true)}>
                        <Space>
                          <SettingOutlined />
                          {translate("settings.settings")}
                        </Space>
                      </a>
                    ),
                    key: 0,
                  },
                  {
                    label: (
                      <div
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent Dropdown from closing
                        }}
                      >
                        <Popconfirm
                          title={translate("automations.delete_title")}
                          description={translate(
                            "automations.delete_description"
                          )}
                          onConfirm={() => {
                            if (id) {
                              deleteMutation(
                                {
                                  id: id,
                                  resource: "automations",
                                },
                                {
                                  onSuccess: () => list("automations"),
                                }
                              );
                            }
                          }}
                          okText={translate("buttons.yes")}
                          cancelText={translate("buttons.no")}
                        >
                          <a style={{ color: "red" }}>
                            <Space>
                              <DeleteOutlined />
                              {translate("automations.delete")}
                            </Space>
                          </a>
                        </Popconfirm>
                      </div>
                    ),
                    key: 1,
                  },
                ],
              }}
              trigger={["click"]}
            >
              <Button
                onClick={(e) => e.preventDefault()}
                icon={<MoreOutlined />}
                size="small"
              />
            </Dropdown>
          </>
        }
      >
        <AutomationCreateUpdate
          edit
          openEdit={modalOpen}
          onCloseEdit={closeEditModal}
        />
        <Tabs
          defaultActiveKey="1"
          centered
          items={items}
          tabBarStyle={{ marginBottom: 20 }}
        />
      </List>
      {children}
    </>
  );
}
