import { useModalForm } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Col, ColorPicker, Form, Input, Modal, Row } from "antd";
import { useUser } from "../../../contexts/ContextProvider";

type Props = {
  id?: string | number;
  open: boolean;
  onClose: () => void;
};

export default function CreateOrUpdate({ open, onClose, id }: Props) {
  const { modalProps, formProps } = useModalForm({
    resource: "tags",
    action: id ? "edit" : "create",
    redirect: false,
    id: id,
    onMutationSuccess: () => {
      onClose();
    },
  });
  const translate = useTranslate();
  const { currentTeam } = useUser();
  return (
    <Modal
      {...modalProps}
      open={open}
      style={{ maxWidth: 600, zIndex: 1000000 }}
      onCancel={(e) => {
        modalProps.onCancel?.(e);
        onClose();
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) =>
          formProps.onFinish &&
          formProps?.onFinish({
            ...values,
            account: currentTeam?.account_id,
          })
        }
      >
        <Row gutter={20}>
          <Col span={20} order={0}>
            <Form.Item
              name={"name"}
              label={translate("tags.title")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4} order={1}>
            <Form.Item name="color" label={translate("tags.color")}>
              <ColorPicker
                onChange={(color) => {
                  formProps.form?.setFieldValue("color", color.toHexString());
                }}
                defaultValue={
                  formProps.form?.getFieldValue("color") ?? "#d1d5db"
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
