import {
  // ApartmentOutlined,
  // AppstoreAddOutlined,
  // EuroCircleOutlined,
  // FileOutlined,
  // MailOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useNavigation, useTranslate } from "@refinedev/core";
import { Avatar, Badge, Button, Flex, List, Space, Typography } from "antd";
import { PropsWithChildren, useEffect, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import useFile from "../../hooks/useFile";
import { getContrastColor } from "../../utility";
import InvitationsList from "../account/invitations-list";
import CustomBreadcrumb from "../common/CustomBreadcrumb";

export default function WorkspacesList({ children }: PropsWithChildren) {
  const { allTeams } = useUser();
  const { getFileDataURL } = useFile();

  const { replace } = useNavigation();
  const translate = useTranslate();
  const [avatars, setAvatars] = useState<{ [key: string]: string | null }>({});

  // Fetch avatar images asynchronously when component mounts
  useEffect(() => {
    const fetchAvatars = async () => {
      const avatarPromises = allTeams.map(async (team) => {
        try {
          if (team?.metadata?.avatar?.image) {
            const imageUrl = await getFileDataURL(team.metadata.avatar.image);
            return { [team.account_id]: imageUrl };
          }
        } catch {
          return { [team.account_id]: null }; // Default to null if fetching fails
        }
      });

      const avatarResults = await Promise.all(avatarPromises);
      const avatarMap = Object.assign({}, ...avatarResults);
      setAvatars(avatarMap);
    };

    fetchAvatars();
  }, [allTeams, getFileDataURL]);

  // const getSettingsItems = (team, translate, replace) => {
  //   const settingsItems = [
  //     {
  //       key: "1",
  //       onClick: () => replace(`/${team.slug}/settings/team`),
  //       label: translate("settings.team"),
  //       icon: <TeamOutlined />,
  //     },
  //     {
  //       key: "2",
  //       onClick: () => replace(`/${team.slug}/settings/subscription-contracts`),
  //       label: translate("settings.subscriptions_contracts"),
  //       icon: <EuroCircleOutlined />,
  //     },
  //     {
  //       key: "3",
  //       onClick: () => replace(`/${team.slug}/settings/invoices`),
  //       label: translate("settings.invoices.invoices"),
  //       icon: <FileOutlined />,
  //     },
  //     {
  //       key: "4",
  //       onClick: () => replace(`/${team.slug}/settings/pipelines`),
  //       label: translate("settings.pipelines"),
  //       icon: <ApartmentOutlined />,
  //     },
  //     {
  //       key: "5",
  //       onClick: () => replace(`/${team.slug}/settings/integrations`),
  //       label: translate("settings.integrations"),
  //       icon: <AppstoreAddOutlined />,
  //     },
  //   ];

  //   const developmentSettingsItems: any = [
  //     {
  //       type: "divider",
  //     },
  //     {
  //       type: "group",
  //       label: (
  //         <Typography.Text style={{ fontSize: 12, color: "#999" }}>
  //           (only visible in development)
  //         </Typography.Text>
  //       ),
  //     },
  //     {
  //       key: "100",
  //       onClick: () => replace(`/${team.slug}/settings/shop`),
  //       label: translate("settings.shop"),
  //       icon: <ShopOutlined />,
  //     },
  //     {
  //       key: "101",
  //       onClick: () => replace(`/${team.slug}/settings/mailing`),
  //       label: translate("settings.mailing"),
  //       icon: <MailOutlined />,
  //     },
  //     // Add other development items here
  //   ];

  //   if (process.env.NODE_ENV !== "production") {
  //     return settingsItems.concat(developmentSettingsItems);
  //   }

  //   return settingsItems;
  // };

  const handleCreateNewWorkspace = () => {
    replace("/team/create");
  };

  const breadcrumbItems = [
    {
      icon: <SettingOutlined />,
      title: translate("teams.manage_workspaces"),
    },
  ];

  return (
    <div style={{ maxWidth: 800, margin: "0 auto" }}>
      <CustomBreadcrumb items={breadcrumbItems} />
      <List
        grid={{ gutter: 16, column: 1 }}
        dataSource={allTeams}
        renderItem={(team: any) =>
          !team.personal_account && (
            <List.Item>
              <Flex
                style={{ borderRadius: 10, marginTop: 20 }}
                justify="space-between"
              >
                <Flex gap={20}>
                  <Badge offset={[0, -20]}>
                    <Avatar
                      shape="circle"
                      size="default"
                      src={avatars[team.account_id] || undefined}
                      style={{
                        backgroundColor: avatars[team.account_id]
                          ? "transparent"
                          : team?.metadata?.avatar?.color,
                        color: getContrastColor(
                          team?.metadata?.avatar?.color ?? "#000"
                        ),
                      }}
                    >
                      {!avatars[team.account_id] && team?.name?.charAt(0)}
                    </Avatar>
                  </Badge>
                  <a href={`/${team?.slug}`}>
                    <Typography.Title level={4}>{team.name}</Typography.Title>
                  </a>
                </Flex>

                <Button onClick={() => replace(`${team.slug}/settings/team`)}>
                    {translate("settings.settings")}
                </Button>
              </Flex>
            </List.Item>
          )
        }
      />

      <Button
        type="link"
        icon={<PlusOutlined />}
        onClick={handleCreateNewWorkspace}
      >
          {translate("teams.create_new_workspace")}
      </Button>

      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <InvitationsList />
      </Space>
      {children}
    </div>
  );
}
