import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useForm } from "@refinedev/antd";
import {
  useList,
  useNavigation,
  useNotification,
  useTranslate,
} from "@refinedev/core";
import {
  Badge,
  Button,
  Col,
  Form,
  Image,
  Input,
  Popconfirm,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import { UploadFile } from "antd/lib";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import { usePipelineContext } from "../../../contexts/PipelineProvider";
import useFile from "../../../hooks/useFile";
import { useTeamInvitation } from "../../../hooks/useTeamInvitation";
import { supabaseClient } from "../../../utility";
import { beforeUpload } from "../../../utility/generalFunctions";
import CustomColorPicker from "../../form/CustomColorPicker";
import InviteMemberModal from "./invite-member";

type Props = {
  open?: boolean;
  onClose?: () => void;
};
const TeamSettings: React.FC<PropsWithChildren & Props> = ({
  children,
  open: openModal,
  onClose,
}) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[] | undefined>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [touch, setTouch] = useState(false);
  const [openInviteMember, setOpenInviteMember] = useState(false);

  const { open } = useNotification();
  const translate = useTranslate();
  const { replace } = useNavigation();
  const { canAccess, setCanAccess } = usePipelineContext();

  const { getFileDataURL, removeFile } = useFile();
  const {
    currentTeam,
    updateTeam,
    refetchTeams,
    allTeams,
    teamMembers,
    fetchTeamMembers,
  } = useUser();
  const { declineInvitation, isLoading } = useTeamInvitation();

  const { data: teamInvitations, refetch } = useList({
    resource: "user_invitations",
    meta: { schema: "basejump" },
    filters: [
      { field: "account_id", operator: "eq", value: currentTeam?.account_id },
    ],
  });
  const mergedTeamMembers = [...teamMembers, ...(teamInvitations?.data ?? [])];

  const { form, formProps } = useForm({
    resource: "accounts",
    action: "edit",
    id: currentTeam?.account_id,
    successNotification: false,
    meta: { schema: "basejump" },
  });

  const usedTeamsColors = useMemo(
    () =>
      allTeams
        ?.map((team) => team.metadata?.avatar?.color)
        .filter(
          (color) =>
            color !== undefined &&
            color !== currentTeam?.metadata?.avatar?.color
        ),
    [allTeams, currentTeam?.metadata?.avatar?.color]
  );
  useEffect(() => {
    const getAvatar = async () => {
      const images = currentTeam?.metadata?.avatar?.image
        ? [currentTeam.metadata.avatar.image]
        : [];
      if (images) {
        const responses = await Promise.allSettled(
          images.map(async (image) => {
            try {
              const fetchedImg = await getFileDataURL(image, true);

              if (!fetchedImg) {
                throw new Error("Fehler beim Lesen der Datei");
              }
              if (fetchedImg && typeof fetchedImg === "object") {
                return {
                  uid: image?.id ?? "",
                  lastModified: fetchedImg.file.lastModified,
                  lastModifiedDate: fetchedImg.file.lastModified,
                  name: fetchedImg.file.name,
                  size: fetchedImg.file.size,
                  type: fetchedImg.blob.type,
                  percent: 100,
                  originFileObj: {
                    uid: image?.id ?? "",
                  },
                  status: "done",
                  response: "ok",
                  thumbUrl: fetchedImg.base64,
                };
              }
            } catch (error: any) {
              console.error(
                `Error fetching image with id ${image?.id}: ${error.message}`
              );
              return null;
            }
          })
        );

        const successfulResponses = responses
          .filter(
            (result): result is PromiseFulfilledResult<any> =>
              result.status === "fulfilled" && result.value !== null
          )
          .map((result) => result.value);
        setFileList(successfulResponses);
      }
    };
    if (currentTeam?.metadata?.avatar?.image) {
      setTimeout(() => {
        getAvatar();
      }, 500);
    }
  }, [currentTeam?.metadata?.avatar?.image, getFileDataURL]);
  if (!currentTeam) {
    // Optional: Ladezustand oder Platzhalter anzeigen, wenn die Daten noch geladen werden
    return <Spin />;
  }
  const handleChange = async ({ fileList }) => {
    if (fileList.length) {
      setFileList(fileList.filter((file) => file.status !== "error"));
    }
  };
  const uploadAvatar = async (options: any) => {
    const { onSuccess, file } = options;
    try {
      if (!currentTeam.account_id) {
        return;
      }
      const { data: fileData, error } = await supabaseClient.storage
        .from("files")
        .upload(`teams/${currentTeam?.account_id}/avatar`, file, {
          upsert: true,
          cacheControl: "no-cache",
        });
      if (error) {
        console.error("Error uploading file: ", error);
        throw Error(error.message, { cause: error.cause });
      }
      await updateTeam(currentTeam.account_id, {
        public_metadata: {
          ...currentTeam.metadata,
          avatar: {
            ...currentTeam.metadata?.avatar,
            image: { ...fileData, name: "avatar" },
          },
        },
      });
      refetchTeams();
    } catch (error) {
      //
    }
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const onRemove = async () => {
    setDeleteConfirmOpen(false);

    setFileList([]);
    if (currentTeam?.metadata?.avatar?.image)
      currentTeam.metadata.avatar.image = null;
    if (currentTeam.account_id) {
      await updateTeam(currentTeam.account_id, {
        public_metadata: currentTeam.metadata,
      });
      await removeFile([`teams/${currentTeam.account_id}/avatar`]);
    }
    localStorage.removeItem("avatarSrc");
    refetchTeams();
  };
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = file.thumbUrl;
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const uploadPropsText: UploadProps = {
    onChange: handleChange,
    multiple: false,
    customRequest: uploadAvatar,
    onRemove: () => {
      setDeleteConfirmOpen(true);
    },
    beforeUpload: beforeUpload,
    showUploadList: false,
    accept: "image/png, image/jpeg, image/jpg, image/webp",
    maxCount: 1,
    onPreview: handlePreview,
  };
  const uploadProps: UploadProps = {
    onChange: handleChange,
    multiple: false,
    customRequest: uploadAvatar,
    onRemove: () => {
      setDeleteConfirmOpen(true);
    },
    beforeUpload: beforeUpload,
    listType: "picture-circle",
    accept: "image/png, image/jpeg, image/jpg, image/webp",
    maxCount: 1,
    onPreview: handlePreview,
    className: "custom-upload",
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection: TableRowSelection<any> = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled:
        (record.account_role === "owner" && record.is_primary_owner) ||
        record.invited_by_account,
    }),
  };
  const removeMemberFromTeam = async () => {
    const data = await supabaseClient.rpc("remove_account_member", {
      user_id: selectedRowKeys[0],
      account_id: currentTeam?.account_id,
    });
    if (data.status != 204) {
      open?.({
        description: translate("alerts.submitError.title"),
        message: translate("alerts.submitError.defaultMessage"),
        type: "error",
      });
    } else {
      open?.({
        description: translate("notifications.success"),
        message: translate("notifications.editSuccess"),
        type: "success",
      });
      fetchTeamMembers();
    }
  };

  return (
    <>
      <Row>
        <Col md={8}>
          <Typography.Title level={4}>
            {translate("teams.settings")}
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {translate("settings.team_intro")}
          </Typography.Paragraph>
        </Col>
      </Row>
      <Form
        {...formProps}
        onFieldsChange={(changedFields, allFields) => {
          setTouch(true);
          formProps.onFieldsChange?.(changedFields, allFields);
        }}
        form={form}
        layout="vertical"
        style={{ marginTop: 50 }}
      >
        <Row gutter={20}>
          <Col md={8}>
            <Typography.Title level={5}>
              {translate("teams.team_name")}
            </Typography.Title>
            <Typography.Paragraph type="secondary">
              {translate("teams.change_name")}
            </Typography.Paragraph>
          </Col>
          <Col md={10}>
            <Form.Item
              name={"team_name"}
              label={translate("teams.name")}
              initialValue={currentTeam?.name}
            >
              <Input.TextArea autoSize={{ minRows: 1 }} />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label={translate("teams.logo")}>
              <Row>
                <Col md={24} style={{ minHeight: 75 }}>
                  {!fileList?.length && (
                    <Space>
                      <Typography.Text>
                        {translate("teams.avatar_color")}:
                      </Typography.Text>
                      <CustomColorPicker
                        excludedColors={usedTeamsColors}
                        className="stage-colorpicker"
                        onChange={async (color) => {
                          if (!currentTeam?.account_id) {
                            return;
                          }
                          await updateTeam(currentTeam?.account_id, {
                            public_metadata: {
                              ...currentTeam?.metadata,
                              avatar: {
                                ...currentTeam?.metadata?.avatar,
                                color: color.toHexString(),
                              },
                            },
                          }).then(() => {
                            refetchTeams();
                          });
                        }}
                        defaultValue={currentTeam?.metadata?.avatar?.color}
                      />
                    </Space>
                  )}
                  <Popconfirm
                    title={translate(
                      "teams.delete_avatar_title",
                      "Delete Avatar"
                    )}
                    description={translate("buttons.confirm", "Are you sure?")}
                    open={deleteConfirmOpen}
                    okText={translate("buttons.yes")}
                    cancelText={translate("buttons.no")}
                    onConfirm={onRemove}
                    placement="bottom"
                    onCancel={() => setDeleteConfirmOpen(false)}
                  >
                    <div>
                      <Upload
                        {...uploadPropsText}
                        fileList={
                          fileList && fileList.length > 0 ? fileList : undefined
                        }
                      >
                        {fileList?.length != 1 && (
                          <Button
                            style={{ padding: 0 }}
                            type="text"
                            icon={<UploadOutlined />}
                          >
                            {translate("buttons.click_to_upload")}
                          </Button>
                        )}
                      </Upload>
                    </div>
                    <div>
                      <Upload
                        {...uploadProps}
                        fileList={fileList}
                        style={{ height: 50 }}
                      />
                    </div>
                  </Popconfirm>
                </Col>

                <div>
                  {previewImage && (
                    <Image
                      wrapperStyle={{ display: "none" }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                          !visible && setPreviewImage(""),
                      }}
                      src={previewImage}
                    />
                  )}
                </div>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row gutter={20}>
        <Col md={8}>
          <Typography.Title level={5}>
            {translate("teams.members.members")}
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {translate("teams.members.list")}
          </Typography.Paragraph>
        </Col>
        <Col md={16}>
          <Table
            dataSource={mergedTeamMembers}
            // dataSource={teamMembers}
            //rowClassName={"clickableRow"}
            //rowSelection={rowSelection}
            pagination={false}
            columns={[
              {
                title: translate("teams.members.name"),
                dataIndex: "name",
                key: "name",

                render: (value, record) => (
                  <Space>
                    {!record.invited_by_account && (
                      <>
                        <Tooltip
                          title={translate(
                            "teams.members.invitation_active",
                            "Active user"
                          )}
                        >
                          <div>
                            <Badge status="success" />
                          </div>
                        </Tooltip>
                      </>
                    )}
                    {record.invited_by_account && (
                      <>
                        <Tooltip
                          title={translate(
                            "teams.members.invitation_pending",
                            "Invitation pending"
                          )}
                        >
                          <div>
                            <Badge status="warning" />
                          </div>
                        </Tooltip>
                      </>
                    )}
                    {value}
                  </Space>
                ),
              },
              {
                title: translate("teams.members.account_role"),
                dataIndex: "account_role",
                key: "account_role",
                render: (role) => {
                  switch (role) {
                    case "member":
                      return translate("teams.members.member");
                    case "owner":
                      return translate("teams.members.owner");
                    default:
                      return role; // Falls keine Übersetzung existiert, Original anzeigen
                  }
                },
              },
              {
                title: translate("teams.members.email"),
                dataIndex: "email",
                key: "email",
              },
              {
                title: translate("teams.members.actions"),
                render: (_value, record) => (
                  <>
                    {record.invited_by_account && (
                      <Button
                        type={"link"}
                        size="small"
                        style={{
                          marginBlock: 0,
                          marginLeft: 10,
                          paddingBlock: 0,
                        }}
                        loading={isLoading}
                        onClick={async () => {
                          try {
                            await declineInvitation(record.id);
                            open?.({
                              description: translate("notifications.success"),
                              message: translate("notifications.revokeSuccess"),
                              type: "success",
                            });
                            refetch();
                          } catch (error) {
                            open?.({
                              description: translate("notifications.error"),
                              message: translate("notifications.revokeError"),
                              type: "error",
                            });
                          }
                        }}
                      >
                        Revoke
                      </Button>
                    )}
                  </>
                ),
              },
            ]}
            size="small"
            style={{ width: "100%", overflowX: "auto" }}
            rowKey={(record) => record.user_id ?? record.id}
            // virtual
            // scroll={{ y: 250 }}
          />

          <Space style={{ marginTop: 20 }}>
            <Button type="link" onClick={() => setOpenInviteMember(true)}>
              <Space>
                <PlusOutlined /> {translate("buttons.add_a_member")}
              </Space>
            </Button>
            {selectedRowKeys.length == 1 && (
              <Popconfirm
                title={translate("teams.members.title")}
                description={translate("teams.members.description")}
                placement="leftBottom"
                onConfirm={() => removeMemberFromTeam()}
                okText={translate("buttons.yes")}
                cancelText={translate("buttons.no")}
              >
                <Button danger>{translate("teams.members.delete")}</Button>
              </Popconfirm>
            )}
          </Space>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginTop: 50 }}>
        <Col md={8}>
          <Typography.Title level={5}>
            {translate("welcome.welcome_page")}
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {translate("welcome.welcome_page_info")}
          </Typography.Paragraph>
        </Col>
        <Col md={16}>
          <Switch
            onChange={async (checked) => {
              const obj = {
                ...currentTeam?.metadata?.module,
                welcome: checked,
              };
              if (currentTeam?.account_id) {
                await updateTeam(currentTeam?.account_id, {
                  public_metadata: {
                    ...currentTeam.metadata,
                    module: {
                      ...currentTeam.metadata?.module,
                      welcome: checked,
                    },
                  },
                });
              }
              setCanAccess({ ...canAccess, personal_modules: obj });
              refetchTeams();
            }}
            defaultValue={canAccess?.personal_modules?.welcome != false}
          />
        </Col>
      </Row>
      <Row>
        <Button
          onClick={() => {
            if (onClose) {
              onClose();
            } else {
              close();
              replace("/workspaces");
            }
          }}
        >
          {!touch ? translate("buttons.close") : translate("buttons.cancel")}
        </Button>
        {touch && (
          <Button
            type="primary"
            disabled={!touch}
            onClick={async () => {
              if (!currentTeam?.account_id) return;
              const test: any = await updateTeam(currentTeam?.account_id, {
                name: form.getFieldValue("team_name"),
              });
              if (test.name === form.getFieldValue("team_name")) {
                refetchTeams();
                open?.({
                  description: translate("notifications.success"),
                  message: translate("notifications.editSuccess"),
                  type: "success",
                });
                setTouch(false);
                // formProps?.form?.resetFields();
                formProps.onFinish?.({});
              }
            }}
          >
            {translate("buttons.save")}
          </Button>
        )}
      </Row>
      <InviteMemberModal
        open={openInviteMember}
        close={() => setOpenInviteMember(false)}
        invitations={mergedTeamMembers}
      />
      {children}
    </>
  );
};
export default TeamSettings;
