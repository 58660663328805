import { PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { useNavigation, useNotification, useTranslate } from "@refinedev/core";
import { Button, Col, Modal, Row, Typography } from "antd";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { usePipelineContext } from "../../contexts/PipelineProvider";
import InfoComponent from "../InfoComponent";

const ShopSettings: React.FC<PropsWithChildren> = ({ children }) => {
  const [touch, setTouch] = useState(false);
  const [modal, setModal] = useState(false);
  const [shopModule, setShopModule] = useState(false);
  const { canAccess, setCanAccess } = usePipelineContext();

  const { currentTeam, updateTeam, refetchTeams } = useUser();
  const { open } = useNotification();
  const translate = useTranslate();

  const { list } = useNavigation();

  async function updateModalStatus(moduleParam: string, value: boolean) {
    const obj = { ...currentTeam?.metadata?.module, [moduleParam]: value };
    if (currentTeam?.account_id) {
      await updateTeam(currentTeam?.account_id, {
        public_metadata: {
          ...currentTeam?.metadata,
          module: obj,
        },
      });
    }
    setCanAccess({ ...canAccess, personal_modules: obj });
    refetchTeams();
    open?.({
      description: translate("notifications.success"),
      message: translate("notifications.editSuccess"),
      type: "success",
    });
  }
  const { modalProps, close } = useModalForm({
    action: "edit",
    defaultVisible: true,
  });
  useEffect(() => {
    if (
      currentTeam?.metadata?.module?.shop !== undefined &&
      currentTeam.metadata?.module?.shop !== null
    )
      setShopModule(currentTeam.metadata?.module?.shop);
  }, [currentTeam?.metadata?.module]);

  return (
    <>
      <Row>
        <Col md={8}>
          <Typography.Title level={4}>
            {translate("settings.shop_title")}
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {translate("settings.shop_intro")}
          </Typography.Paragraph>
        </Col>
      </Row>
      {shopModule ? <>{translate("buttons.coming_soon")}</> : <InfoComponent />}
      {children}
      <Modal
        open={modal}
        onOk={() => {
          setShopModule(!shopModule);
          updateModalStatus("shop", !shopModule);
          setModal(false);
        }}
        title={
          shopModule ? (
            <>
              <WarningOutlined />
              {translate("settings.disable_shop")}
            </>
          ) : (
            <>
              <PlusOutlined />
              {translate("settings.enable_shop")}
            </>
          )
        }
        onCancel={() => setModal(false)}
      >
        {shopModule
          ? translate("settings.disable_shop_info")
          : translate("settings.enable_shop_info")}
      </Modal>
      <Row>
        {canAccess?.modules?.shop && shopModule ? (
          <>
            <Button
              danger
              type="link"
              onClick={async () => {
                setModal(true);
              }}
            >
              {translate("settings.disable_shop")}
            </Button>
          </>
        ) : (
          <>
            <Button
              type="link"
              onClick={async () => {
                setModal(true);
              }}
              disabled={canAccess?.modules?.shop === false}
            >
              {translate("settings.enable_shop")}
            </Button>
          </>
        )}
      </Row>
    </>
  );
};
export default ShopSettings;
